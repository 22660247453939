<div [class.high-contrast]="isHighContrast()">
  <div *ngIf="!isFormatTypeTextLong()">
    <div style="display:inline-block; vertical-align:middle; margin-right:0.2em;" *ngIf="element.prefix">
      {{element.prefix}}
    </div>
    <div style="display:inline-block; vertical-align:middle; ">
      <div *ngIf="isFormatTypeNumber()">
        <input
          type="text" 
          [readonly]="this.isLocked || element.isDisabled"
          [formControl]="numberInput" 
          (blur)="onNumberInputBlur()" 
          (focus)="onNumericInputFocusIn(numberInput)"
          class="input" 
          [class.has-shadow]="hasBoxShadow()"
          style="text-align:center" 
          [style.width.em]="getWidthNum('mathCharLimit', 'value')"
          [style.border-width.em]="getInputBoxBorderThickness()"
          [style.border-color]="getInputBoxBorderColor()"
          [style.font-weight]="element.inputFontWeight? element.inputFontWeight : 400">
      </div>
      <div *ngIf="isFormatTypeFraction()">
        <div style="display:inline-block; vertical-align:middle;">
          <div *ngIf="element.isMixedNumber" style="display:inline-block; vertical-align:middle; margin-right:0.2em;">
            <input 
              [readonly]="this.isLocked || element.isDisabled"
              type="text" 
              [formControl]="fractionWholeInput" 
              (focus)="onNumericInputFocusIn(fractionWholeInput)"
              (blur)="onNumericInputFocusOut()"
              [style.width.em]="getWidthNum('', 'fracWholeNumber')"
              class="input input-fraction" 
              [class.has-shadow]="hasBoxShadow()"
              [style.border-width.em]="getInputBoxBorderThickness()"
              [style.border-color]="getInputBoxBorderColor()">
          </div>
          <div style="display:inline-block; vertical-align:middle;">
            <input 
              [readonly]="this.isLocked || element.isDisabled"
              type="text"
              [formControl]="fractionNumeInput" 
              (focus)="onNumericInputFocusIn(fractionNumeInput)"
              (blur)="onNumericInputFocusOut()"
              [style.width.em]="getFractionWidth()"
              class="input  input-fraction is-small"
              [class.has-shadow]="hasBoxShadow()"
              [style.border-width.em]="getInputBoxBorderThickness()"
              [style.border-color]="getInputBoxBorderColor()" >
            <div style="margin-bottom:6px; border:1px solid #333;"></div>
            <input 
              [readonly]="this.isLocked || element.isDisabled"
              type="text" 
              [formControl]="fractionDenoInput" 
              (focus)="onNumericInputFocusIn(fractionDenoInput)"
              (blur)="onNumericInputFocusOut()"
              [style.width.em]="getFractionWidth()"
              class="input  input-fraction is-small"
              [class.has-shadow]="hasBoxShadow()"
              [style.border-width.em]="getInputBoxBorderThickness()"
              [style.border-color]="getInputBoxBorderColor()" >
          </div>
        </div>
      </div>
      <div *ngIf="isFormatTypeTextShort()">
        <input 
          type="text" 
          class="input" 
          [formControl]="textInput" 
          [ngStyle]="{'width.em':element.maxChars*1 + 2}"
          [readonly]="this.isLocked || element.isDisabled"
          [class.has-shadow]="hasBoxShadow()"
          [style.border-width.em]="getInputBoxBorderThickness()"
          [style.border-color]="getInputBoxBorderColor()"
        />
      </div>
      <div *ngIf="isFormatTypeRatio()">
        <span *ngFor="let listEntry of ratioTerms; let listIndex = index;">
          <span *ngIf="listIndex !== 0" style="margin-left:0.3em; margin-right:0.3em;">:</span>
          <input 
            [readonly]="this.isLocked || element.isDisabled"
            type="text" 
            class="input" 
            [formControl]="listEntry" 
            (focus)="onNumericInputFocusIn(listEntry)"
            (blur)="onNumericInputFocusOut()"
            style="width:40px; vertical-align:middle; text-align:center"
            [class.has-shadow]="hasBoxShadow()"
            [style.border-width.em]="getInputBoxBorderThickness()"
            [style.border-color]="getInputBoxBorderColor()" >
        </span>
      </div>
      <div *ngIf="isFormatTypeAlgebra()" style="min-width:12em"> 
        <capture-math 
          [obj]="latexCapture" 
          prop="latexInput"
          [isLocked]="isLocked"
          [isManualKeyboard]="true"
          [isSimple]="element.isAlgebraSimple"
          (onChange)="updateState()"
          [borderSettings]="getCaptureMathBorderSettings()"
        ></capture-math>
      </div>
      <div *ngIf="isFormatTypeAlgebraList()">
        <div *ngFor="let algebraVal of algebraListInput; let listIndex = index" style="display: inline-block;">
          <capture-math 
            [obj]="algebraListGiven"
            [isLocked]="isLocked"
            [isManualKeyboard]="true"
            (onChange)="updateState()"
            [latexValue]="algebraListInput[listIndex].value" 
            [control]="algebraVal"
            [isSimple]="element.isAlgebraSimple"
            style="display: inline-block; min-width:12em"
            [borderSettings]="getCaptureMathBorderSettings()"
          ></capture-math>
          <div style="display: inline-block; margin: 0 0.2em;">{{algebraSuffixListInput[listIndex].value}}</div>
        </div>
      </div>
      <div *ngIf="isFormatTypeAlgebraGroups()">
        <div *ngFor="let algebraVal of algebraGroupsInput; let listIndex = index" style="display: inline-block;">
          <capture-math 
            [obj]="algebraGroupsGiven"
            [isLocked]="isLocked"
            [isManualKeyboard]="true"
            (onChange)="updateState()"
            [latexValue]="algebraGroupsInput[listIndex].value" 
            [control]="algebraVal"
            [isSimple]="element.isAlgebraSimple"
            style="display: inline-block; min-width:12em"
            [borderSettings]="getCaptureMathBorderSettings()"
          ></capture-math>
          <div style="display: inline-block; margin: 0 0.2em;">{{algebraSuffixGroupsInput[listIndex].value}}</div>
        </div>
      </div>
      <div *ngIf="isFormatNumberListTypeForm()">
        <div *ngFor="let numberVal of numberListInput; let listIndex = index" style="display: inline-block;">
          <input 
            [readonly]="this.isLocked || element.isDisabled"
            type="text" 
            [formControl]="numberVal" 
            (blur)="onNumberListInputBlur()" 
            (focus)="onNumericInputFocusIn(numberVal)"
            class="input" 
            style="width:5em; text-align:center; margin:0 0.2em; vertical-align: baseline;"
            [class.has-shadow]="hasBoxShadow()"
            [style.border-width.em]="getInputBoxBorderThickness()"
            [style.border-color]="getInputBoxBorderColor()" >
          <div style="display: inline-block; margin-right: 0.2em;">{{suffixListInput[listIndex].value}}</div>
        </div>
      </div>
      <div *ngIf="isFormatNumberGroupsTypeForm()">
        <div *ngFor="let numberVal of numberGroupsInput; let listIndex = index" style="display: inline-block;">
          <input 
            [readonly]="this.isLocked || element.isDisabled"
            type="text" 
            [formControl]="numberVal" 
            (blur)="onNumberGroupsInputBlur()" 
            (focus)="onNumericInputFocusIn(numberVal)"
            class="input" 
            style="width:5em; text-align:center; margin:0 0.2em; vertical-align: baseline;" 
            [class.has-shadow]="hasBoxShadow()"
            [style.border-width.em]="getInputBoxBorderThickness()"
            [style.border-color]="getInputBoxBorderColor()"
            >
          <div style="display: inline-block; margin-right: 0.2em;">{{suffixGroupsInput[listIndex].value}}</div>
        </div>
      </div>
    </div>
    <div style="display:inline-block; vertical-align:middle; margin-left:0.2em;" *ngIf="element.suffix">
      {{element.suffix}}
    </div>
  </div>
  
  <div
      *ngIf="isFormatTypeTextLong()" [style.font-size.em]="getInputFontSize()"
  >
    <div *ngIf="isPlainTextArea()">
      <textarea 
        *ngIf="!element.isTranscript"
        #textArea
        class="textarea is-fullwidth" 
        style="width: 24em;"
        [formControl]="textInput"
        [readonly]="this.isLocked || element.isDisabled"
        [class.has-shadow]="hasBoxShadow()"
        [style.border-width.em]="getInputBoxBorderThickness()"
        [style.border-color]="getInputBoxBorderColor()"
      ></textarea>
      <div *ngIf="element.isTranscript && allowTranscripts && accommodation && textInput.value && textInput.value!=''">
        <button class="button_show-transcript" (click)="showTranscript=!showTranscript">
          <tra *ngIf="!showTranscript" slug="auth_audio_show_transcript"></tra>
          <tra *ngIf="showTranscript" slug="auth_audio_close_transcript"></tra>
        </button>
        <textarea
          *ngIf="showTranscript"
          #textArea
          class="textarea is-fullwidth"
          style="resize: none;"
          [style.height.em]="getTranscriptBoxFixedHeight()"
          [style.min-height.em]="getTranscriptBoxFixedHeight()"
          [style.max-height.em]="getTranscriptBoxFixedHeight()"
          [readonly]="true"
          [class.has-shadow]="hasBoxShadow()"
          [style.border-width.em]="getInputBoxBorderThickness()"
          [style.border-color]="getInputBoxBorderColor()"
        ></textarea>
        <!-- <div *ngIf="transcriptTimer!=-1">{{transcriptTimer}}&nbsp;<tra slug="auth_transcript_time_left"></tra></div> -->
      </div>
      
    </div>
    <div
      *ngIf="!isPlainTextArea()"
      style="position:relative;" 
      [class.hide-toolbar-ckeditor]="element.isToolbarAutoHide && !isInFocus"
      [class.fixed-height-ckeditor]="element.isFixedHeight"
      (mouseenter)="hoverOver()"
      [class.has-shadow]="hasBoxShadow()"
      [style.border-width.em]="getInputBoxBorderThickness()"
      [style.border-color]="getInputBoxBorderColor()"
    >
      <!-- <div *ngIf="!isCkEditorInFocus()" (click)="focusInCkEditor()" style="cursor:text" tabindex="0">
        <div class="cke-placeholder" [innerHTML]="textInput.value"></div>
        <div 
          *ngIf="isBlankText() && element.defaultText && !isInFocus" 
          class="default-text-container"
          [style.justify-content] = "element.defaultTextAlignment || 'center'"
          [class.is-hide-toolbar]="element.isToolbarAutoHide && !isInFocus"
          [class.is-in-fixed-height]="element.isFixedHeight"
        >
          <div #defaultText class="default-text" [style.color]="element.defaultTextColour" [innerHTML]="element.defaultText"></div>
        </div>
      </div>
      <ng-container *ngIf="isCkEditorInFocus()"> -->
        <ckeditor 
          *ngIf="ckEditorConfig"
          [editor]="Editor" 
          [config]="ckEditorConfig" 
          (focus)="!!isInFocus" 
          (blur)="!isInFocus" 
          (ready)="onCkEditorReady($event)" 
          (change)="onCkEditorUpdate($event)"
          [disabled]="isLocked || isShortAnswerBlocked()"
        ></ckeditor>
        <div 
          *ngIf="isBlankText() && element.defaultText && !isInFocus" 
          [class.default-text-container]="!isSafari()"
          [class.default-text-container-safari]="isSafari()"
          [style.justify-content] = "element.defaultTextAlignment || 'center'"
          [class.is-hide-toolbar]="element.isToolbarAutoHide && !isInFocus"
          [class.is-in-fixed-height]="element.isFixedHeight"
          [class.is-hi-contrast-default-text]="text2Speech.isHiContrast"
          [class.is-safari-default-text-hc]="isSafari() && text2Speech.isHiContrast"
          [style.height]="getDefaultTextContainerHeight()"
          (mouseover)="hoverOver()"
        >
          <div #defaultText class="default-text" [class.is-safari-default-text] = "isSafari()" [style.color]="isHighContrast() ? '' : element.defaultTextColour" [innerHTML]="element.defaultText"></div>
          <render-audio 
              *ngIf="element.voiceover && element.voiceover.url"
              [url]="element.voiceover.url" 
              [trigger]="getHoverTrigger()" 
              [isTriggerDisabled]="!isVoiceOverEnabled()"
          ></render-audio>
        </div>
      <!-- </ng-container> -->
    </div>
    
    <div [ngStyle]="{'display':displayKeyboard}" class="keyboard-container" *ngIf="element.isShowingFrenchKeys" (mousedown)="$event.preventDefault()">
      <div class="keyboard">
        <div>
          <button class="button is-small character " style="width: 5em;" [class.is-warning]="!isShowingCapitals" [class.is-dark]="isShowingCapitals" (click)="isShowingCapitals=!isShowingCapitals">
            Maj.
          </button>
        </div>
        <ng-container *ngIf="!isShowingCapitals">
          <div *ngFor="let letter of frenchAccent">
            <button class="button is-small character" (click)="addFrenchCharacter(letter, $event.target, $event);">
              {{letter}}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="isShowingCapitals">
          <div *ngFor="let letter of frenchAccentCapital">
            <button class="button is-small character" (click)="addFrenchCharacter(letter, $event.target, $event);">
              {{letter}}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
      <!-- (ready)="onReady($event)"
      -->
    <div *ngIf="element.selectedLimitType == 'word'" [ngSwitch]="!!isCountdownMode()"> 
      <ng-container *ngSwitchCase="true">
        <small *ngIf="this.element.maxWords && getRemainingWords()>=0" class="remaining-chars" [class.is-complete]="getRemainingWords() == 0" [class.is-hi-contrast-word-count]="isWordCountDisplayHiContrast()">
          <tra slug="open_response_rem_words">{{getRemainingWords()}}</tra><span>{{getRemainingWords()}}</span>
        </small>
        <small *ngIf="this.element.maxWords && getRemainingWords()<0" class="remaining-chars is-overflow" >
          <tra slug="open_response_rem_words"></tra><span>{{-1*getRemainingWords()}}</span> 
        </small>
      </ng-container>
      <ng-container  *ngSwitchCase="false">
        <small *ngIf="!element.maxWords && element.isShowingWordCount" class="remaining-chars" [class.is-complete]="getRemainingWords() == 0 && !element.isShowingWordCount" [class.is-overflow]="getWords() > element.maxWords && !element.isShowingWordCount" [class.is-hi-contrast-word-count]="isWordCountDisplayHiContrast()">
          <tra slug="open_response_words"></tra><span>{{getWords()}} {{element.maxWords ? '/ '+ element.maxWords : ''}}</span>
        </small>
        <small *ngIf="element.maxWords" class="remaining-chars" [class.is-complete]="getRemainingWords() == 0" [class.is-overflow]="getWords() > element.maxWords" [class.is-hi-contrast-word-count]="isWordCountDisplayHiContrast()">
          <tra slug="open_response_words"></tra><span>{{getWords()}} / {{element.maxWords}}</span>
        </small>
      </ng-container>
    </div>
    
    <div *ngIf="element.selectedLimitType == 'char'" [ngSwitch]="!!isCountdownMode()"> 
      <ng-container *ngSwitchCase="true">
        <small *ngIf="this.element.maxChars && getRemainingChars()>=0" class="remaining-chars" [class.is-complete]="getRemainingChars() == 0" [class.is-hi-contrast-word-count]="isWordCountDisplayHiContrast()">
          <tra slug="open_response_rem_chars"></tra>{{getRemainingChars()}}
        </small>
        <small *ngIf="this.element.maxChars && getRemainingWords()<0" class="remaining-chars is-overflow" >
          <tra slug="open_response_rem_chars"></tra> {{-1*getRemainingChars()}}
        </small>
      </ng-container>
      <ng-container  *ngSwitchCase="false">
        <small *ngIf="element.maxChars" class="remaining-chars" [class.is-complete]="getRemainingChars() == 0" [class.is-overflow]="getCharArray().length > element.maxChars" [class.is-hi-contrast-word-count]="isWordCountDisplayHiContrast()">
          <tra slug="open_response_chars"></tra><span>{{getCharCount()}}</span>
        </small>
      </ng-container>
    </div>

  </div>

  <div *ngIf="isFormatTypeForm()">
    <div *ngFor="let element of element.formElements">
      <element-render 
        [contentElement]="element" 
        [isLocked]="isLocked" 
        [questionState]="questionState"
        [questionPubSub]="questionPubSub"
      ></element-render>
    </div>
  </div>

  <div 
    [ngStyle]="{'display':displayNumericKeyboard}" 
    style="align-items:flex-start;"
    class="keyboard-container" 
    *ngIf="element.isShowingNumericKeyboard" 
    (mousedown)="$event.preventDefault()">
    <div class="keyboard">
        <div *ngFor="let digit of keyboardLocal.digits">
          <button class="button is-small character" (click)="addKeyboardCharacter($event, digit);">
            {{digit}}
          </button>
        </div>
        <div *ngIf="element.isShowingNumericKeyboardWithDecimal">
          <button class="button is-small character" (click)="addKeyboardCharacter($event, keyboardLocal.signs.decimal);">
            {{keyboardLocal.signs.decimal}}
          </button>
        </div>
    </div>
  </div>
</div>

