import { API_ADDRESS_LOCAL } from "../../api/api-addr/l";
import { IContextData } from "./common";

export const BCED: IContextData = {
  id: 'BCED',
  // homepageRoute: '/en/general/login/false',
  homepageRoute: '/en/bced-landing/admin',
  brandName: 'BC Education eAssessment',
  logo: 'bc_landing_logo',
  footer: null,
  apiAddress: (hostname: string) => {
    switch (hostname) {
      case 'bced.vretta.com':
      case 'd29vll157davgh.cloudfront.net':
        return 'https://bced-api.vretta.com';
      case 'localhost2':
      case 'localhost':
      case 'localhost:4200':
      case 'localhost:4201':
      case '26dc5ff374e3.ngrok.io':
        // return 'https://bced-api.vretta.com';
        return API_ADDRESS_LOCAL;
        // return "https://bced-uat-api.vretta.com"
        // return "https://bced-api.vretta.com"
        // return "https://bced-qc-api.vretta.com"
        // return 'https://bced-xqc1-api.vretta.com';
        // return "https://bced-api.vretta.com"
        // return "https://bced-mirror-qc-api.vretta.com"
        // return 'https://bced-testing-api.vretta.com'
      case 'bced-qc.vretta.com': // field test
      case 'bced-qc-asmt-preview.vretta.com':
      case 'd3ri0mgp55tj8c.cloudfront.net':
      case 'bced-asmt-preview.vretta.com':
        return "https://bced-qc-api.vretta.com"
      case 'bced-mirror-qc.vretta.com':
      case 'd2m0j3pg0r08mv.cloudfront.net':
        return "https://bced-mirror-qc-api.vretta.com"
      case 'bced-uat.vretta.com':
      case 'd3sqlx10iftz08.cloudfront.net':
        return "https://bced-uat-api.vretta.com"
      case 'bced-qc-fsa.vretta.com':
      case 'd10kwjh1otxvkj.cloudfront.net':
        return "https://bced-qc-fsa-api.vretta.com"
      case 'bced-qc-grad.vretta.com':
      case 'dvlhk5zohu0ms.cloudfront.net':
        return "https://bced-qc-grad-api.vretta.com"
      case 'bced-testing.vretta.com':
      case 'd111ququcad1fr.cloudfront.net':
        return 'https://bced-testing-api.vretta.com'
      case 'bced-qc-mark.vretta.com':
        return "https://bced-qc-mark-api.vretta.com"
      case 'bced-qc-mkg.vretta.com':
      case 'd5nzzmi2a2z1s.cloudfront.net':
        return "https://bced-qc-mkg-api.vretta.com"
      case 'bced-ltp.vretta.com':
      case 'd1cog685yb52eq.cloudfront.net':
        return "https://bced-ltp-api.vretta.com"
      case 'bced-prod-mirror.vretta.com':
        return 'https://bced-prod-mirror-api.vretta.com';
      case 'bced-feat1.vretta.com':
      case 'bced.vretta.com': // field test
      case 'bced-feat2.vretta.com':
      case 'bced-feat1.vretta.com':
      default:
        return "https://bced-api.vretta.com"
    }
  },
  siteFlags: {
    'INST_MNG_TT': true,
    'IS_BCED': true,
    'TEST_CTRL_REPORT_EXPLR': true,
    'TEST_CTRL_DATA_QUERY': true,
    'TEST_RUNNER_WIDTH_CONSTRAINT': false,
    'IS_BC_FIELD_TEST_2021': false,
    'QUESTION_DEBUG_LOGS': false,
  },
  siteText: {
    copyright: '', //  © Vretta Inc. 2021
    supportEmail: 'bc_support_email',
    supportPhone: '1-888-887-3882',
    login_admins: 'lbl_administrators',
    my_inst: 'My School',
    my_inst_admin: 'You are the **administrator** of this school.',
    my_inst_manage_acct: 'Manage Teachers+',
    // asmt_logo_en: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/bc-logo/1610467447537/bc-logo.png',
    // asmt_logo_fr: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/bc-logo/1610467447537/bc-logo.png',
    header_bg_color: '#003366',
    header_logo_en: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/bc-logo/1610467447537/bc-logo.png',
    header_logo_fr: 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/38543/authoring/fr-BC_V_pos-v2/1613521513099/fr-BC_V_pos-v2.png',
    student_ident: 'PEN',
    exemplar: 'exemplar',
    exemplars: 'exemplars',
    mark: 'mark',
    marked: 'marked',
    marker: 'marker',
    markers: 'markers',
    marking_chat: 'Scorings Chat',
    marking: 'marking',
    marks: 'marks',
    reliability: 'reliability',
    tech_support: 'tech_support_bc',
    TnR: 'T&R',
    txt_support_popup: 'tech_support_bc',
    report_logo_en: '/assets/BC-logos/BC_EDUC_H_RGB_pos.png',
    report_logo_fr: '/assets/BC-logos/BC_Francais_H_RGB_pos.png',
    report_logo_alt: '/assets/BC-logos/Yukon-Logo-CMYK.png',
    sa_isrr_logo: 'sa_isrr_logo'
  },
  hosts: [
    '26dc5ff374e3.ngrok.io',
    '46734d1c3012.ngrok.io',
    'bced-feat1.vretta.com',
    'bced-feat2.vretta.com',
    'bced-qc.vretta.com',
    'bced.vretta.com',
    // 'localhost:4201',
    // 'localhost2:4200',
  ],
  defaultTimezone: 'America/Vancouver',
}
