import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { IContentElementTextSelection, TextParagraphStyle, QuestionState, IEntryStateMcq, getElementWeight, ScoringTypes, ElementType } from '../models';
import { LangService } from '../../core/lang.service';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { PubSubTypes } from '../element-render-frame/pubsub/types';



const SCORING_TYPE = ScoringTypes.AUTO;

interface SelectableWord {
  index: number,
  word: string,
}

@Component({
  selector: 'element-render-select-text',
  templateUrl: './element-render-select-text.component.html',
  styleUrls: ['./element-render-select-text.component.scss']
})
export class ElementRenderSelectTextComponent implements OnInit {

  @Input() element:IContentElementTextSelection;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() questionPubSub?: QuestionPubSub;

  constructor(private lang: LangService) { }

  blocks:SelectableWord[] = [];
  paragraphs: SelectableWord[][]
  isSelected = []

  ngOnInit() {
    this.setBlocks();
    if(this.questionState && this.questionState[this.element.entryId]) {
      for(let selection of this.questionState[this.element.entryId].selections) {
        this.isSelected[selection.i] = true;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ensureState()
    if (changes.changeCounter) {
      this.setBlocks();
    }
  }

  setBlocks() {
    if (!this.element.texts) return;
    this.blocks = []
    this.isSelected = []
    this.paragraphs = []

    if(this.element.isParagraphMode){
      this.element.texts = []
      let sentenceIdx = 0
      let wordIdx = 0
      this.element.paragraphs.forEach(paragraph => {
        let para = []
        paragraph.forEach((block,index) => {
          if (block["content"]!=undefined) {
            const wordList = block["content"].split(' ');
            wordList.forEach((word)=>{
              this.blocks.push({ word: word, index:sentenceIdx})
              para.push({ word: word, index:sentenceIdx, wordIdx})
              wordIdx++
            })
            sentenceIdx++
            this.isSelected.push(false);
            this.element.texts.push(block)
          }
        })
        this.paragraphs.push(para)
      })
      return
    }

    this.element.texts.forEach((block, index)=>{
      if (block["content"]!=undefined) {
        const wordList = block["content"].split(' ');
        wordList.forEach((word)=>{
          this.blocks.push({ word: word, index})
        })
        this.isSelected.push(false);
      }
    })
  }

  showMaxSelectedMsg;
  toggleAnswer(index:number) {
    console.log("Toggle ans, index is: ", index)
    if (this.isSelected[index]) {
      this.showMaxSelectedMsg = false;
      this.isSelected[index]=false;
    }
    else {
      let canSelect = true;
      if (this.element.isSelectionsLimited) {
        if (this.element.maxSelections > 1) {
          let count = 0;
          this.isSelected.forEach((selection)=>{
            if (selection) {
              count++;
            }
          })
          if (count>=this.element.maxSelections) {
            canSelect = false;
          }
        } else if (this.element.maxSelections == 1) {
          for (let i = 0;i<this.isSelected.length;i++) {
            this.isSelected[i] = false;
          }
        }
        
      }
      if (canSelect ==false) {
        this.showMaxSelectedMsg = true;
        return;
      }
      this.isSelected[index]=true; 
    }
    this.updateState();
  }

  getMaxMsgText() {
    if (this.lang.getCurrentLanguage()=="en") {
      return "You have made the maximum number of selections.\nPlease unselect one to make a new selection."
    } else {
      return "Vous avez effectué le nombre maximum de sélections.\nVeuillez en désélectionner un pour faire une nouvelle sélection."
    }
  }

  getClickToDismissMsg() {
    return this.lang.tra('btn_click_dismiss');
    // if (this.lang.getCurrentLanguage()=="en") {
    //   return "Click to dismiss"
    // } else {
    //   return "Cliquez pour ignorer"
    // }
  }

  addBorder(style:any, border:string, block:SelectableWord, index:number) {
    style["border-top"] = border;
    style["border-bottom"] = border;

    if (index!=0 && block.index == this.blocks[index-1].index) {
      style["border-left"]="none"
    } else {
      style["border-left"]=border;
    }

    if (index < this.blocks.length-1 && this.blocks[index].index == this.blocks[index+1].index ) {
      style["border-right"]="none";
    } else {
      style["border-right"]=border;
    }
  }

  addBorder_Sentence(style:any, border:string, sentence:IContentElementTextSelection, index:number) {
    style["border-top"] = border;
    style["border-bottom"] = border;

    if (index!=0 && index == this.blocks[index-1].index) {
      style["border-left"]="none"
    } else {
      style["border-left"]=border;
    }

    if (index < this.blocks.length-1 && this.blocks[index].index == this.blocks[index+1].index ) {
      style["border-right"]="none";
    } else {
      style["border-right"]=border;
    }
  }

  getWordStyle(block:SelectableWord, index:number) {
    let style:any = {};
    if (this.isSelected[block.index]) style["background-color"]="rgba(255,255,0, 0.4)";

    if (this.element.hasOutline) {
      if (!this.isSelected[block.index]) {
        this.addBorder(style, "2px dotted black", block, index)
      }
      else {
        this.addBorder(style, "2px dotted red", block, index)
      }
    }
    style["min-height"]="1.5em"
    return style;
  }

  getWordStyle_Sentence(sentence:IContentElementTextSelection, index:number) {
    let style:any = {};
    if (this.isSelected[index]) style["background-color"]="rgba(255,255,0, 0.4)";

    if (this.element.hasOutline) {
      if (!this.isSelected[index]) {
        this.addBorder_Sentence(style, "2px dotted black", sentence, index)
      }
      else {
        this.addBorder_Sentence(style, "2px dotted red", sentence, index)
      }
    }
    style["min-height"]="1.5em"
    return style;
  }

  getWordStyle_Sentence_2(paragraph, sentence, index:number) {
    let style:any = {};
    let sentenceIndex = this.getSentenceIndex(paragraph, sentence)
    if (this.isSelected[sentenceIndex]) style["background-color"]="rgba(255,255,0, 0.4)";

    if (this.element.hasOutline) {
      if (!this.isSelected[sentenceIndex]) {
        this.addBorder_Sentence(style, "2px dotted black", sentence, index)
      }
      else {
        this.addBorder_Sentence(style, "2px dotted red", sentence, index)
      }
    }
    style["min-height"]="1.5em"
    return style;
  }

  getFormattedWord(word: string) {
    return word+"&nbsp;"
  }

  getFormattedSentence_2(sentence) {
    return sentence+"&nbsp"
  }

  getSentences(paragraph: SelectableWord[]){
    if (paragraph.length > 0){
      let numOfSentences = paragraph[paragraph.length - 1].index
      let sentences = [];
      for (let i = 0; i <= numOfSentences; i++) {
        let sentenceArr = paragraph.filter(block => block.index == i)
        let sentence = sentenceArr.map(({word}) => word).join(' ')
        if(sentence !== '')
          sentences.push(sentence)
        // if (sentenceArr.length > 0)
        //   sentences.push(sentenceArr)
      }
      return sentences
    }
    return ''
  }

  getSentenceIndex(paragraph, sentenceInput){
    let numOfSentences = paragraph[paragraph.length - 1].index
    let sentences = [];
    for (let i = 0; i <= numOfSentences; i++) {
      let sentenceArr = paragraph.filter(block => block.index == i)
      let sentence = sentenceArr.map(({word}) => word).join(' ')
      if(sentence !== '')
        sentences.push({content: sentence, index: i})
    }

    let sentenceObj = sentences.filter(sentence => sentence.content === sentenceInput)
    return sentenceObj[0].index
  }

  getSentences_Block(){
    if (!this.blocks[this.blocks.length - 1]){
      return [];
    }
    let numSentences = this.blocks[this.blocks.length - 1].index
    let sentences = []
    for( let i = 0; i <= numSentences; i++){
      let sentenceArr = this.blocks.filter(block => block.index == i)
      let sentence = sentenceArr.map(({word}) => word).join(' ')
      if(sentence !== '')
        sentences.push(sentence)
    }
    return sentences
  }

  ensureState() {
    if (this.questionState){
        const entryId = this.element.entryId;
        if (!this.questionState[entryId]){
            let entryState:IEntryStateMcq = {
            type: 'mcq',
            isCorrect: false,
            isStarted: false,
            isFilled: false,
            isResponded: false,
            selections: [],
            alreadyScrambled: true,
            score: 0,
            weight: getElementWeight(this.element),
            scoring_type: ScoringTypes.AUTO, 
            }
            this.questionState[entryId] = entryState;
        }
    }
  }

  updateState() {
    let isCorrect = true;
    let isStarted = false;
    let isFilled = false;
    let weight = getElementWeight(this.element);
    let score = this.isSelected.length;
    let numOfCorrectOptions = this.element.texts.filter(text => text.isCorrect).length;
    let numOfSelectedCorrect = 0;

    let selections = []
    this.isSelected.forEach((isSelect, index)=>{
      if (isSelect) {
        isStarted = true;
        isFilled = true;
        const option = this.element.texts[index];
        selections.push({i: index, id: option.optionId, elementType: ElementType.TEXT, content: option.content})
      }

      if ((isSelect && !this.element.texts[index].isCorrect) || (!isSelect && this.element.texts[index].isCorrect)) {
        isCorrect = false;
        score--;
      } 

      if (isSelect && this.element.texts[index].isCorrect){
        numOfSelectedCorrect++;
      }
    })
    
    if (score === this.element.texts.length){
      score = weight
    }
    else {
      score = 0;
    }

    if (this.element.enableProportionalScoring){
      score = numOfSelectedCorrect / numOfCorrectOptions * weight
    }
    
    let isResponded =  this.questionState[this.element.entryId].isResponded || isFilled;
    
    // console.log(score)
    let entryState:IEntryStateMcq = {
      type: 'mcq',
      isCorrect,
      isStarted,
      isFilled,
      isResponded,
      selections,
      alreadyScrambled: false,
      score,
      weight,
      scoring_type: SCORING_TYPE, 
    }

    if (this.questionState){
      this.questionState[this.element.entryId] = entryState
    }

    if(this.questionPubSub){
      this.questionPubSub.allPub({entryId: this.element.entryId, type: PubSubTypes.UPDATE_VALIDATOR, data: {}})
    }
  }

  getStyle(idx?) {
    let style = {};
    if (this.element.width) {
      style['width']=this.element.width+'em';
      style['max-width']=this.element.width+'em';
      style['min-width']=this.element.width+'em';
      if(this.element.isParagraphMode){
        style['margin-bottom.em'] =  idx == this.paragraphs.length - 1 ? 0 : this.element.spaceAfterParagrapgh
      }
    }
    return style;
  }
}
