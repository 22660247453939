<div *ngIf="testState && !testState.isSubmitted" class="screen-warning">
    <tra slug="error_small_screen"></tra>
</div>
<div id="ghost" style="position:absolute; visibility: hidden; width: 10em; height:0.01em"></div>
<div *ngIf="!testState.isSubmitted && !isPrintMode" class="question-content-frame" [class.results-intro]="pageMode===PageMode.RESULTS_INTRO" [class.is-hi-contrast]="isHighContrast">    

    <div *ngIf="!isTestNavExpanded">
        <button class="button" (click)="toggleTestNav()">
            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
        </button>
    </div>
    <div class="test-nav" 
        [class.isSafari10]="isSafari10()"
        [style.font-size.em]="(getZoomValue()/defaultZoomLevel)/getScreenShrink()"
        [class.is-expanded]="isTestNavExpanded" 
        [class.is-onTop]="isTestNavOnTop"
        *ngIf="!isLineReaderActive && !isLeftBarDisabled()"
    >
        <div>
            <!-- <div class="collapser">
                <button class="button" (click)="toggleTestNav()">
                    <i class="fa fa-angle-double-left" aria-hidden="true"></i>
                </button>
            </div> -->
            <div class="support" *ngIf="false && isIssueReportingEnabled">
                <button (click)="reportIssue()">
                    <img src="https://mathproficiencytest.ca/img/red-flag.png">
                    <tra slug="btn_report_issue"></tra>
                </button>
            </div>

            <div *ngIf="isExitEnabled">
                <button class="button is-small is-fullwidth" (click)="editItem()">
                    <span>Edit this Item</span>
                </button>
            </div>
            
            <div *ngIf="isExitEnabled">
                <button class="button is-small is-fullwidth  is-danger has-icon" (click)="textToSpeech.hi_contrast_off();exit.emit()">
                    <span class="icon"><i class="fa fa-times"></i></span>
                    <span>Exit Preview</span>
                </button>
            </div>
            
            <div class="identity">
                <div class="space-between">
                    <div class="identity-name">
                        <markdown 
                            class="markdown is-condensed" 
                            [data]="getTestTakerName()" 
                            ngPreserveWhitespaces
                        ></markdown>
                    </div>
                    <button class="button" (click)="toggleTestNav()" *ngIf="!checkTag(KNOWN_TEST_RUNNER_TAGS.NO_LEFT_COLLAPSE)">
                        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
                    </button>
                </div>
                <div *ngIf="attemptKey" class="identity-key">
                <tra slug="txt_dummy_attempt_key"></tra>: {{attemptKey}}</div>
            </div>

            <div *ngIf="getLogo()" class="logo-container">
                <img [src]="getLogoSafe()">
            </div>

            <div *ngIf="asmtCaption" class="test-title">
                <tra-md [slug]="asmtCaption" [isCondensed]="true"></tra-md>
            </div>
            
            <div *ngIf="isSectionNavDropdownEnabled && !isShowingResults" class="test-section">
                <div class="section-navigation-dropdown">
                    <button 
                        id="section-navigation-dropdown-trigger"
                        class="dropdown-trigger" 
                        (click)="toggleNavigationDropdown()" 
                        [disabled]="questionsForNavigation.length === 0"
                    >
                        <span><tra slug="btn_navigate_assessment"></tra></span>
                        <i class="icon fas fa-angle-down" aria-hidden="true"></i>
                    </button>
                    <div class="dropdown-content" [class.is-collapsed]="!isSectionNavDropdownOpen">
                        <button class="dropdown-item" *ngFor="let question of questionsForNavigation" (click)="onQuestionNavigation(question)">
                            {{question.anchorLabel}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="test-section">
                <div *ngIf="testRunnerSections.length > 1 && !isShowingResults" [ngSwitch]="!!useSectionCaptions()">
                    <span *ngSwitchCase="true">
                        {{getCurrentSectionCaption()}}
                    </span>
                    <span *ngSwitchCase="false">
                        <tra [slug]="getSectionTitleSlug()"></tra>
                        {{(getCurrentSectionIndex() + 1)}}
                        <tra slug="of_enum"></tra>
                        {{testRunnerSections.length}}
                    </span>
                </div>
                <div *ngIf="isShowingResults">
                    <tra [slug]="getResultTitleSlug()"></tra>
                </div>
                <!-- style="margin-left: 2em;" -->
                <div *ngIf="getCurrentSectionPreambleContent()" style="margin-top: 0.5em;">
                    <a *ngIf="isFlushNavigation() && !isShowingResults" (click)="showSectionInfo()">
                        <!--<img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/Essential-question-Unselected/1611767079590/Essential-question-Unselected.png">-->
                        <div class="button-back" *ngIf="!isShowingSectionInfo"><div class="image-container" style="max-width: 70%;"><img [src]="getCurrentSection().preambleThumbnail"></div><div class="button-text">{{getCurrentSection().preambleThumbnailText}}</div></div>
                        <div class="button-back is-active" *ngIf="isShowingSectionInfo"><div class="image-container" style="max-width: 70%;"><img [src]="getCurrentSection().preambleThumbnailSelected"></div><div class="button-text">{{getCurrentSection().preambleThumbnailText}}</div></div>
                    </a>
                    <button *ngIf="!isFlushNavigation()" (click)="showSectionInfo()" class="button is-small is-fullwidth" [class.is-info]="isShowingSectionInfo">
                        <i *ngIf="!getInfoCaption()" class="fa fa-info"></i>
                        <span *ngIf="getInfoCaption()">{{getInfoCaption()}}</span>
                    </button>
                </div>
                <div *ngIf="getCurrentSection().isTimeLimit && !isFlushNavigation()" style="font-size: 0.85em; font-weight: 400;">
                    <tra [slug]="getSectionTimeRemainingSlug()"></tra>: {{sectionTimeRemaining}}
                </div>
            </div>
        </div>
        <div class="test-questions" [class.isSafari10]="isSafari10()" [class.hide-flags]="!isAnyFlaggedQuestions()">
            <div class="question-navbar-container">
                <div class="question-navbar">
                    <ng-container *ngIf="isReviewQuestionsEnabled && isOnReviewQuestionsPage">
                        <div class="question-navbar-block" (click)="reviewQuestionPage()" [class.is-active]="pageMode === PageMode.REVIEW_QUESTIONS" *ngIf="getCurrentPageModeCaption()">
                            <button class="question">
                                <tra [slug]="getCurrentPageModeCaption()"></tra>        
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isShowingResults">
                        <ng-container *ngFor="let mode of pageModeFlow; let i = index">
                            <div class="question-navbar-block" [class.is-active]="pageMode === mode.slug" (click)="selectPageMode(i)" *ngIf="isPageEnabledInResultsMode(mode)">
                                <button class="question">
                                    <tra *ngIf="!isCustomNavbarResultsIntroPageTitle(mode.slug)" [slug]="mode.caption"></tra>
                                    {{isCustomNavbarResultsIntroPageTitle(mode.slug) ? getCustomNavbarResultsIntroPageTitle() : ''}}                                </button>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="(!isShowingResults || showResultsDetailPages()) && !isOnReviewQuestionsPage">
                        <div class="question-navbar-block" *ngFor="let question of getCurrentQuestions(); let qIndex = index;" tabindex="0" (click)="selectQuestion(qIndex)" [class.is-active]="!isShowingSectionInfo && getCurrentQuestionIndex() === qIndex && pageMode === PageMode.TEST_RUNNER">
                            <button  class="question test-question--entry">
                                <img *ngIf="isQuestionFlagged(qIndex)" class="coloured-flag" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/flag (coloured)/1607961301596/flag (coloured).svg" alt=""/>
                                <span *ngIf="!isQuestionFlagged(qIndex)" class="coloured-flag invisible-flag"></span>
                                <span class="icon">
                                    <ng-container *ngIf="!isShowingResults">
                                        <i *ngIf="!isQuestionFilled(qIndex)" class="far fa-circle" [tooltip]="lang.tra('lbl_not_filled')" placement="left" aria-hidden="true"></i>
                                        <i *ngIf="isQuestionFilled(qIndex)" class="fas fa-circle" [tooltip]="lang.tra('lbl_filled')" placement="left" aria-hidden="true"></i>
                                    </ng-container>
                                    <ng-container *ngIf="isShowingResults">
                                        <ng-container *ngIf="!isQuestionUnmarked(question)">
                                            <!-- <img *ngIf="isQuestionCorrect(question)" style="width: 15px" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6276/authoring/correctcircle/1612215146148/correctcircle.png"> -->
                                            <!-- <img *ngIf="!isQuestionCorrect(question)" style="width: 15px" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6276/authoring/incorrectcircle/1612215224414/incorrectcircle.png"> -->
                                            <i *ngIf="isQuestionCorrect(question)" class="fas fa-check-circle fa-lg fa-fw" style="color: #0080FF;"></i>
                                            <i *ngIf="!isQuestionCorrect(question)" class="far fa-dot-circle fa-lg fa-fw" style="color: #0080FF;"></i>
                                        </ng-container>
                                        <!-- <img *ngIf="isQuestionUnmarked(question)" style="width: 15px" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6276/authoring/yellowclock/1612215246552/yellowclock.png"> -->
                                        <i *ngIf="isQuestionUnmarked(question)" class="fas fa-keyboard fa-lg fa-fw" [style.color]="getKeyboardIconColor()"></i>
                                    </ng-container>
                                </span>
                                <span>
                                    {{getQuestionTitleFromId(question)}}
                                </span>
                            </button>
                        </div>
                    </ng-container>                        
                    <div *ngIf="!isShowingResults" (click)="reviewAndSubmit()" class="question-navbar-block">
                        <button class="question">
                            <tra [slug]="getSubmissionText()"></tra>
                        </button>
                    </div>
                    <div *ngIf="isShowingResults" (click)="leaveResults()" class="question-navbar-block">
                        <button  class="question" >
                            <tra slug="tr_btn_exit_results"></tra>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class='test-timing back-to-map' *ngIf="checkIsOsslt()" (click)="goBackToMap()">
            <i class="fas fa-angle-left"></i> <tra slug="osslt_back_to_map"></tra>
        </div>
        <div class='test-timing back-to-map' *ngIf="checkIsOssltTools()" (click)="goBackToMenu()">
            <i class="fas fa-angle-left"></i> <tra slug="osslt_back_to_menu"></tra>
        </div>
        <div class="test-timing" *ngIf="!isNavFooterDisabled">
            <button (click)="checkTimeLeft()" *ngIf="isTimeEnabled">
                <img src="https://mathproficiencytest.ca/img/clock.png">
            </button>
            <button (click)="openChat()" *ngIf="isChatEnabled">
                <i class="far fa-comments" aria-hidden="true"></i>
            </button>
            <button *ngIf="chatService.newMessage" (click)="openChat()">
                <a><i class="fa fa-envelope" aria-hidden="true"></i></a>
            </button>
        </div>
    </div>

    <ng-container [ngSwitch]="pageMode">
        <ng-container *ngSwitchCase="PageMode.RESULTS_INTRO">
            <div class="results-page " [ngStyle]="{'font-size.em': getZoomValue()}">
                <div class="centered-div results-intro-div" *ngIf="!asmtFmrk.customResultPage">
                    <div *ngIf="!asmtFmrk.isOrale && !asmtFmrk.customResultPageTitle" style="font-size: 2em;font-weight: bold">
                        <tra-md [isCondensed]="true" slug="bc-results-page-text"></tra-md>
                    </div>
                    <div *ngIf="!asmtFmrk.isOrale && asmtFmrk.customResultPageTitle" style="font-size: 2em;font-weight: bold">
                        <markdown 
                            class="markdown" 
                            [data]="asmtFmrk.customResultPageTitle" 
                            [class.is-condensed]="true"
                            ngPreserveWhitespaces>
                        </markdown>
                    </div>
                    <div>
                        <tra-md [isCondensed]="true" slug="tr_results_intro_text_1" [props]="{ASSESSMENT_NAME: getAssessmentName(true)}"></tra-md>
                    </div>
                    <div *ngIf="getCustomResultsText()" style="text-align:left;">
                        <!--<tra-md [isCondensed]="true" slug="tr_results_intro_text_2"></tra-md>-->
                        <tra-md [isCondensed]="true" [slug]="getCustomResultsText()"></tra-md>
                    </div>
                    <div *ngIf="showResultsDetailPages()">
                        <!--<tra-md [isCondensed]="true" slug="tr_results_intro_text_2"></tra-md>-->
                        <tra-md [isCondensed]="true" slug="tr_results_intro_text_2"></tra-md>
                    </div>
                </div>
                <ng-container *ngIf="asmtFmrk.customResultPage">
                    <div class="centered-div results-intro-div custom-result-page" [innerHTML]="getCustomResultsPage()"></div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="PageMode.RESULTS_SUMMARY">
            <div class="results-page results-summary" [ngStyle]="{'font-size.em': getZoomValue()}">
                <div class="centered-div">
                    
                    <div style="font-size:2.1em; font-weight:600; line-height: 1.2em;"><tra [slug]="getResultSummaryTitleSlug()"></tra></div>


                    <results-summary-sr style="width:100%"
                        [numCorrectSRQuestions]="finalReportStats.numCorrectSRQuestions"
                        [numSRQuestions]="finalReportStats.numSRQuestions"
                        [correctSRScore]="finalReportStats.correctSRScore" 
                        [totalSRScore]="finalReportStats.totalSRScore"
                        [showScore]="showReportOptions(reportOptions.SCORE)"
                        [isCustomResultPageNote]="asmtFmrk.isCustomResultPageNote"
                        [customResultPageNoteEN]="asmtFmrk.customResultPageNoteEN"
                        [customResultPageNoteFR]="asmtFmrk.customResultPageNoteFR"
                        [frameWorkTagsRef]="frameWorkTagsRef">                        
                    </results-summary-sr>
                    
                    <ng-container *ngIf="finalReportStats.numCRQuestions">
                        <results-summary-cr style="width:100%"
                            [totalCRScore]="finalReportStats.totalCRScore"
                            [numCRQuestions]="finalReportStats.numCRQuestions"
                            [frameWorkTagsRef]="frameWorkTagsRef"
                        >
                        </results-summary-cr>
                    </ng-container>
                    <div><tra-md [isCondensed]="true" [slug]="getResultSummaryText1Slug()"></tra-md></div>
                    <button class="button results-export-button" (click)="exportResults()"><img class="img-export-button" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/96360/authoring/export_image/1619731475625/export_image.png"/><tra style="margin-bottom: 0.1em;" slug="test_auth_export"></tra></button>
                    <ng-container *ngIf="isNotTestTaker() && rubricDownloadLink">
                        <div><tra-md [isCondensed]="true" slug="tr_results_summary_text_rubric"></tra-md></div>
                        <button (click)="downloadRubric()" class="button results-export-button"><img class="img-export-button" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/96360/authoring/export_image/1619731475625/export_image.png"/><tra slug="tr_results_view_rubric"></tra></button>
                        <a #rubricLink [href]="rubricDownloadLink"></a>
                    </ng-container>
                    <div><tra-md [isCondensed]="true" [slug]="getResultSummaryText2Slug()"></tra-md></div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="PageMode.RESULTS_INSTRUCTIONS">
            <div class="results-page results-instructions" [ngStyle]="{'font-size.em': getZoomValue()}">
                <div class="centered-div">
                    <div></div>
                    <div style="font-size:2.1em; font-weight:600; line-height: 1.2em;"><tra slug="tr_results_instr_title"></tra></div>
                    <div><tra-md [isCondensed]="true" slug="tr_results_legend_text_1"></tra-md></div>
                    <div *ngIf="showReportOptions(reportOptions.LEGEND)"><tra-md [isCondensed]="true" slug="tr_results_legend_text_2"></tra-md></div>
                    <results-legend [initExpand]="true" [numCRQuestions]="!!finalReportStats.numCRQuestions" style="width: 20em"></results-legend>
                    <div><tra-md [isCondensed]="true" slug="tr_results_legend_text_3"></tra-md></div>
                    <div *ngIf="finalReportStats.numCRQuestions"><tra-md [isCondensed]="true" slug="tr_results_legend_text_4"></tra-md></div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="PageMode.REVIEW_QUESTIONS">
            <div class="review-page" [style.font-size.em]="getZoomValue()">
                <div class="top-bar" [class.is-hi-contrast]="isHighContrast" >
                    <div class="flex-column" style="justify-content: space-between;">
                        <div style="font-weight:600; font-size:1.3em"> 
                            <tra slug="review_questions_page_title"></tra>                            
                        </div>
                        <div style="margin-top: 1em; font-size:0.8em">
                            <tra slug="review_questions_page_title_info"></tra>                            
                        </div>
                    </div>
                </div>
            
                <div class="filter-area" >
                    <div [class.is-hi-contrast]="isHighContrast">
                        <tra slug="review_questions_page_filters"></tra>
                        <i class="fas fa-sliders-h review-icon" style="margin-left: 0.5em;"></i>
                    </div>
                    <div (click)="handleReviewPageFilters(reviewPageFilterMode.VIEWALL)" [class.is-selected]="reviewPageFilters.viewAll"><tra slug="review_questions_page_filters_viewall"></tra></div>
                    <div (click)="handleReviewPageFilters(reviewPageFilterMode.UNANSWERED)" [class.is-selected]="reviewPageFilters.unAnswered"><tra slug="review_questions_page_filters_unfilled"></tra> 
                        &nbsp;<i class="far fa-circle" aria-hidden="true"></i>
                        ({{totalUnAnswered}})</div>
                    <div (click)="handleReviewPageFilters(reviewPageFilterMode.FLAGGED)" [class.is-selected]="reviewPageFilters.flagged"><tra slug="review_questions_page_filters_flagged"></tra> 
                        <img style="margin-right:0em;margin-bottom:0.5em;" class="flag-image" [src]="getFlagURL()" alt=""/>
                        
                        ({{totalFlagged}})</div>
                </div>
            
                <div class="questions-list" [class.is-hi-contrast]="isHighContrast">
                    <div class="sections" *ngFor="let section of testRunnerSections; let sectionIdx = index">
                        <div class="section-title" [class.is-hi-contrast]="isHighContrast" *ngIf="!isSectionScoringDisabled(sectionIdx)">{{section.caption}}</div>
                        <div class="questions" [class.is-hi-contrast]="isHighContrast" *ngFor="let qId of section.questions; let qIdx = index">
                            <div class="flag-space" *ngIf="ensureDisplayQuestion(qId, sectionIdx) && filteredQuestion(qId)">
                                <img *ngIf="isQuestionFlaggedByqId(qId)" class="flag-image" [src]="getFlagURL()" alt=""/>
                            </div>
                            <div class="question-card" [class.is-hi-contrast]="isHighContrast" *ngIf="ensureDisplayQuestion(qId, sectionIdx) && filteredQuestion(qId)">
                                <div class="flex-column" [class.is-hi-contrast]="isHighContrast" style="justify-content: space-between; color:#0080FF; margin-top:0.25em ">
                                    <i *ngIf="!isQuestionFilledByqId(qId)" class="far fa-circle" [tooltip]="lang.tra('lbl_not_filled')" placement="left" aria-hidden="true"></i>
                                    <i *ngIf="isQuestionFilledByqId(qId)"  class="fas fa-circle" [tooltip]="lang.tra('lbl_filled')" placement="left" aria-hidden="true"></i>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div class="question-info">
                                    <a class="blue" (click)="selectQuestion(qIdx,sectionIdx)">{{getQuestionTitleFromId(qId)}}</a>
                                    <span *ngIf="getReviewQuestionText(qId)" [innerHTML]="getReviewQuestionText(qId)" [class.is-hi-contrast]="isHighContrast" style="margin-top: 0.5em;"></span>
                                    <span *ngIf="isReviewQuestionTimeSpent()">{{getTotalTimeSpentOnQuestion(qId)}}</span> 
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="PageMode.TEST_RUNNER">
            <div class="question-content" id="quest-cont" [class.is-split-view]="getActiveQuestionReadSel()">
            <!--<button *ngIf="false" tabindex="1" aria-label="Keyboard Instructions" style="display: none;background-color: white; position: absolute; left: 0; color: #3298dc; border-radius: 5px; padding: 0.75em; border: 2px solid #3298dc">Keyboard Instructions</button>-->
            <div class="helper-tools" [style.font-size.em]="getZoomValue()">
                <div 
                    #questionDisplay 
                    class="question-content-constrainer no-padding" 
                    [class.needs-padding]="getActiveQuestionReadSel()" 
                    [style.width]="getActiveQuestionReadSel() ? '100%' : null" 
                >

                    <ng-container>
                        <!-- *ngIf="questionResetter" -->
                        <div class="split-view-container" *ngIf="questionResetter">
                            <div #scrollIndicator cdkScrollable class="split-view-left" *ngIf="isShowingLeft()" [class.is-solo]="!isShowingRight()" [class.is-saving]="isSavingResponse  && !isQuietlySavingResponse">
                                <div id="ghost-split-left" style="position:absolute; visibility: hidden; width: 10em; height:0.01em"></div>

                                <div *ngIf="isProgressBarEnabled()" class="progress-bar-container">
                                    <div class="question-loc-sm">
                                        <tra slug="title_stage"></tra> {{testState.currentSectionIndex+1}},
                                        {{getQuestionTitle(testState.currentQuestionIndex)}}
                                    </div>
                                    <div  style="position:relative" #topBar [class.is-hidden]="isLineReaderActive" [ngSwitch]="!!checkTag(KNOWN_TEST_RUNNER_TAGS.QUESTIONS_FILLED_PROGRESS_BAR) || checkIsOsslt()">
                                        <ng-container *ngSwitchCase="false || undefined">
                                            <progress  class="progress is-info" style="margin-bottom:0px;" [value]="getCurrentProgressLoc(true)" max="100">{{getCurrentProgressLoc()}}</progress>
                                            <div *ngFor="let section of testRunnerSections; let isLast = last" class="section-progress-marker" [ngStyle]="{left: section.__meta.markLoc}" [class.is-hidden]="isLast"> </div>
                                        </ng-container>
                                        <ng-container  *ngSwitchCase="true"> <!--  -->
                                            <progress class="progress is-info" style="margin-bottom:0px;" [value]="getCurrentProgressBySession(currentSession, 0)" max="100"></progress>
                                        </ng-container>
                                    </div>
                                </div>
                                
                                <div *ngIf="isHelpOverlay" class="question-content question-content-help" style="height: auto;">
                                    <div class="question-content-constrainer" style="margin-bottom:2em; height: auto;;" >
                                        <question-runner 
                                            #questionRunner
                                            [currentQuestion]="getHelpScreenLayout()" 
                                            [containingWidth]="getQuestionRunnerWidth('main')"
                                            [containingWidthSpill]="getQuestionRunnerSpillover('main')"
                                            [questionState]="helpPageState"
                                            [allowSubtitles]="isAllowedSubtitles()"
                                            [allowTranscripts]="isAllowedTranscripts()"
                                            [allowAudioPlaybackSpeed]="isAllowedAudioPlaybackSpeed()"
                                            [allowVideoPlaybackSpeed]="isAllowedVideoPlaybackSpeed()"
                                            (manualSave)="manualSaveQuestion()"
                                            [isFSA]="checkTag('BC_FSA')"
                                        ></question-runner>
                                    </div>
                                </div>

                                <!-- Display Preamble: -->
                                <ng-container *ngIf="isShowingSectionInfo && !isHelpOverlay">
                                    <div *ngIf="getCurrentSectionPreambleContent()" style="position: relative;" class="question-runner-padder">
                                        <div *ngIf="isInfoIconEnabled()" style="font-size: 2em; color: #3298dc; position:absolute; left:-1em; top:0.1em;">
                                            <i class="fa fa-info"></i>
                                        </div>
                                        <question-runner 
                                            #questionRunner
                                            [currentQuestion]="getCurrentSectionPreambleContent()" 
                                            [containingWidth]="getQuestionRunnerWidth('main')"
                                            [containingWidthSpill]="getQuestionRunnerSpillover('main')"
                                            [allowSubtitles]="isAllowedSubtitles()"
                                            [allowTranscripts]="isAllowedTranscripts()"
                                            [allowAudioPlaybackSpeed]="isAllowedAudioPlaybackSpeed()"
                                            [allowVideoPlaybackSpeed]="isAllowedVideoPlaybackSpeed()"
                                        ></question-runner>
                                    </div>
                                    <div class="next-button-container" *ngIf="!isFlushNavigation()">
                                        <button (click)="hideSectionInfo()" id="btn-submit-question" class="button is-info" [class.is-large]="!isFlushNavigation()" >
                                            <tra-md [slug]="getNextPageSlug()" [isCondensed]="true" [props]="{QUESTION_WORD: getQuestionWord()}" ></tra-md>
                                        </button>
                                    </div>
                                </ng-container>

                                <!-- Display Current Question:    -->
                                <ng-container *ngIf="!isShowingSectionInfo && !isHelpOverlay">
                                    <div  *ngIf="isFlushNavigation()" class="top-bar is-in-frame">
                                        <div style="display: flex; flex-direction: column">
                                            <div class="align-center">
                                                <!-- <button class="button" *ngIf="!isTestNavExpanded" (click)="toggleTestNav()">
                                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                                </button> -->
                                                <span class="question-title-span" style="font-weight:600" [style.color]="isHighContrast ? '#fff' : '#000'" tabindex="0">
                                                   <span *ngIf="isShowingResults"><tra slug='bc-results-page-header'></tra>&nbsp;-&nbsp;</span> {{getCurrentQuestionTitle()}}</span>
                                                <span *ngIf="getCurrentPoints()" [style.color]="isHighContrast ? '#fff' : ''" class="points-indicator">({{getCurrentPoints()}})</span>
                                            </div>
                                            <div *ngIf="isFlagEnabledForCurrentQuestion() && !isShowingResults" style="font-size: 0.7em; margin-top:0.8em;" class="flag-button-container">
                                                <!-- class="button is-small is-info is-outlined" -->
                                                <a (keypress)="flagKeyPress($event)" [style.color]="isHighContrast ? '#ff0' : ''" style="font-weight: 400;" tabindex="0" (click)="flagCurrentQuestion()">
                                                    <img class="flag-image" [src]="getFlagURL()" alt=""/> 
                                                    <tra [slug]="getFlagSlug()" *ngIf="!isCurrentQuestionFlagged()"></tra> 
                                                    <tra [slug]="getFlagSlug(true)" style="color: red;" *ngIf="isCurrentQuestionFlagged()"></tra>
                                                </a>
                                            </div>
                                        </div>
                                        <ng-container>
                                            <div *ngIf="hasExactlyOneReadingSelection()" class="reading-selections-container">
                                                <div class="reading-selections" [style.color]="isHighContrast ? '#ff0' : ''" style="margin-top:0.5em;">
                                                    <element-render-text-link *ngIf="readerInfo && readerInfo[0]" [element]="convertReadSelToTextLink(readerInfo[0])" [isHighContrast]="isHighContrast" (readSelViewClose)="readSelViewClose()"></element-render-text-link>
                                                </div>
                                            </div>
                                            <div *ngIf="hasReadingSelections()" class="reading-selections-container">
                                                <div class="reading-selections" [style.color]="isHighContrast ? '#ff0' : ''">
                                                    <div *ngIf="isReadingSelectionListToggle()">
                                                        <a (keydown.enter)="toggleReadingSelections()" [style.color]="isHighContrast ? '#ff0' : ''" (click)="toggleReadingSelections()" tabindex="0" [ngSwitch]="!!isShowingReadingSelections">
                                                            <i *ngSwitchCase="false" class="icon fas fa-caret-right"></i>
                                                            <i *ngSwitchCase="true" class="icon fas fa-caret-down"></i>
                                                            <tra [slug]="getOpenReadSelSlug(isShowingReadingSelections)"></tra>
                                                        </a>
                                                        <div *ngIf="isShowingReadingSelections" class="reading-selection-options">
                                                            <div class="reading-selection-option" [style.color]="isHighContrast ? '#ff0' : ''" *ngFor="let readSel of readerInfo; let i = index">
                                                                <element-render-text-link  [element]="convertReadSelToTextLink(readSel)" [isHighContrast]="isHighContrast" (readSelViewClose)="readSelViewClose()"></element-render-text-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="!isReadingSelectionListToggle()">
                                                        <div class="reading-selection-options">
                                                            <div class="reading-selection-option" *ngFor="let readSel of readerInfo; let i = index">
                                                                <element-render-text-link  [element]="convertReadSelToTextLink(readSel)" [isHighContrast]="isHighContrast" (readSelViewClose)="readSelViewClose()"></element-render-text-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="question-runner-padder">
                                        <div style="position:relative;">
                                            <div >
                                                <question-runner 
                                                    #questionRunner
                                                    [currentQuestion]="getActiveQuestionContent()" 
                                                    [questionState]="getActiveQuestionState()"
                                                    [containingWidth]="getQuestionRunnerWidth('split-left')"
                                                    [containingWidthSpill]="getQuestionRunnerSpillover('split-left')"
                                                    [isSubmitted]="isShowingResults"
                                                    [allowSubtitles]="isAllowedSubtitles()"
                                                    [allowTranscripts]="isAllowedTranscripts()"
                                                    [allowAudioPlaybackSpeed]="isAllowedAudioPlaybackSpeed()"
                                                    [allowVideoPlaybackSpeed]="isAllowedVideoPlaybackSpeed()"
                                                    (manualSave)="manualSaveQuestion()"
                                                    [isFSA]="checkTag('BC_FSA')"
                                                ></question-runner>
                                            </div>
                                            <div *ngIf="isShowDrawing && resetter && questionResetter" class="question-content__overlay" [ngClass]="{'deactivated':!isShowOverlay}" id="draw-overlay-split">
                                                <element-render-drawing  
                                                    id="test-runner" 
                                                    [parent]="getDrawingDisplayMode()" 
                                                    [currId]="getCurrentLeftItemId()" 
                                                    [activatedState]="isShowDrawing" 
                                                    [useEraser]="showEraser" 
                                                    [useHighlighter]="showHighlight" 
                                                    [useLine]="showLine" 
                                                    [zoomLevel]="getZoomValue()" 
                                                    [isZoomIn]="isZoomIn"
                                                    [isFrameEnabled]="false"
                                                    [isToolbarEnabled]="false"
                                                    [drawingCtx]="loadDrawing()"
                                                    [pageIndexTracker]="drawingPageIndexTracker"
                                                    [section]="getCurrentSectionIndex()"
                                                    (savedCtx)="saveDrawing($event)"
                                                    [clearAllDrawings]="clearAllDrawings"
                                                    (resetClearAll)="resetClearDrawing($event)"
                                                ></element-render-drawing>
                                            </div>
                                            <div 
                                                class="question-content__faux-overlay" 
                                                [class.animateDown]="isShowOverlay"
                                                [class.animateUp]="!isShowOverlay"
                                            ></div>
                                        </div>
                                    </div>
                                </ng-container>

                                <div  *ngIf="!isFlushNavigation()" [ngSwitch]="!!isOnLastQuestion()" class="next-button-container test-question" style="display:block; flex-direction: column;" [class.is-hidden]="isShowingSectionInfo">
                                    <button *ngSwitchCase="false" (click)="gotoNextQuestion()" id="btn-submit-question" [disabled]="!isCurrentQuestionFilled() && !isCurrentQuestionFlagged() && !areTestQuestionsSkippable() && !forceQuestionsSkippable"   class="button is-large is-info">
                                        <tra-md [slug]="getNextPageSlug()" [isCondensed]="true" [props]="{QUESTION_WORD: getQuestionWord()}" ></tra-md>
                                    </button>
                                    <button *ngSwitchCase="true" (click)="reviewAndSubmit()" id="btn-submit-question" [disabled]="!isCurrentQuestionFilled() && !isCurrentQuestionFlagged()"  class="button is-large is-info">
                                        <tra [slug]="getSubmissionText()"></tra>
                                    </button>
                                    <div *ngIf="isFlagEnabledForCurrentQuestion()" class="flag-button-container">
                                        <!-- class="button is-small is-info is-outlined" -->
                                        <a (keypress)="flagKeyPress($event)" tabindex="0" (click)="flagCurrentQuestion()">
                                            <img class="flag" [src]="getFlagURL()" alt=""/> 
                                            <tra [slug]="getFlagSlug(false)" *ngIf="!isCurrentQuestionFlagged()"></tra> 
                                            <tra [slug]="getFlagSlug(true)" style="color: red;" *ngIf="isCurrentQuestionFlagged()"></tra>
                                        </a>
                                    </div>
                                </div>
            
                                <!-- <div style="height:6em;"></div> -->

                                <div class="scroll-indication" *ngIf="scrollIndicationStatus">
                                  <i class="icon fas fa-arrow-down fa-fw"></i> 
                                </div>

                            </div>
                            <div cdkScrollable class="split-view-right" [class.is-solo]="!isShowingLeft()" id="readingPassageSplit" *ngIf="isShowingRight() && getActiveQuestionReadSel() &&!isShowingSectionInfo">
                                <!-- Reading selection -->
                                <div id="left-split-container">
                                    <div id="ghost-split-right" style="position:absolute; visibility: hidden; width: 10em; height:0.01em"></div>
                                    <div class="question-runner-padder-disabled">
                                        <question-runner *ngIf="activeReadingSelection" id="questionRunnerInReadingPassage" 
                                            [currentQuestion]="activeReadingSelection"
                                            [containingWidth]="getQuestionRunnerWidth('split-right')"
                                            [containingWidthSpill]="getQuestionRunnerSpillover('split-right')"
                                            [questionState]="getReadingSelectionState()"
                                            [isSubmitted]="isShowingResults"
                                            [allowSubtitles]="isAllowedSubtitles()"
                                            [allowTranscripts]="isAllowedTranscripts()"
                                            [allowAudioPlaybackSpeed]="isAllowedAudioPlaybackSpeed()"
                                            [allowVideoPlaybackSpeed]="isAllowedVideoPlaybackSpeed()"
                                            (manualSave)="manualSaveQuestion()"
                                            [isFSA]="checkTag('BC_FSA')"
                                            #questionRunner
                                        ></question-runner>
                                    </div>
                                    <div *ngIf="isShowDrawing && resetter && questionResetter" class="question-content__overlay" [ngClass]="{'deactivated':!isShowOverlay}" id="draw-overlay-split">
                                        <element-render-drawing  
                                            id="test-runner" 
                                            [parent]="getDrawingDisplayMode()" 
                                            [currId]="getCurrentRightItemId()" 
                                            [activatedState]="isShowDrawing" 
                                            [useEraser]="showEraser" 
                                            [useHighlighter]="showHighlight" 
                                            [useLine]="showLine" 
                                            [zoomLevel]="getZoomValue()" 
                                            [isZoomIn]="isZoomIn"
                                            [isFrameEnabled]="false"
                                            [isToolbarEnabled]="false"
                                            [drawingCtx]="loadDrawing()"
                                            [pageIndexTracker]="drawingPageIndexTracker"
                                            [section]="getCurrentSectionIndex()"
                                            (savedCtx)="saveDrawing($event)"
                                            [clearAllDrawings]="clearAllDrawings"
                                            (resetClearAll)="resetClearDrawing($event)"
                                        ></element-render-drawing>
                                    </div>
                                    <div 
                                        class="question-content__faux-overlay" 
                                        [class.animateDown]="isShowOverlay"
                                        [class.animateUp]="!isShowOverlay"
                                    ></div>
                                    
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        </ng-container>
    </ng-container>


    <div class="toolbar-surrogate" ></div>
    <!-- *ngIf="pageMode === PageMode.TEST_RUNNER"  -->

</div>
<div *ngIf="!testState.isSubmitted && !isPrintMode" class="test-runner-overlays">

    <!-- [style.max-width.px]="this.screenWidth" -->

    <div *ngIf="isLineReaderActive">
        <div class="line-reader-container" id="quest-reader" [ngStyle]="{'font-size.em': getZoomValue()}" (window:mouseup)="resizeLineReaderWidth($event, false);">
            <div cdkDrag class="line-reader" #lineReader [style.width.px]="lineReaderWidth" >
                <div class="line-reader-top"></div>
                <div class="line-reader-left"></div>
                <div class="line-reader-right"></div>
                <div class="line-reader-bottom"></div>
                <div class="resize-line-reader" (mousedown)="resizeLineReaderWidth($event, true);" >
                    <div class="resizer-tool">
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isNotepadEnabled" [ngStyle]="{'font-size.em': getZoomValue()}">
        <div cdkDrag class="notepad-container" >
            <div style="padding-bottom:0.5em;">
                <tra [slug]="getNotepadSlug()"></tra>
            </div>
            <div *ngIf="getNotepadText()" style="    padding-bottom: 0.6em;
            border-bottom: 1px solid #fff;
            margin-bottom: 0.5em;
            line-height: 1.1em;
            font-weight: 500;
            font-size: 0.7em;;">
                {{getNotepadText()}}
            </div>
            <ng-container [ngSwitch]="!!(isFlushNavigation() || checkIsOsslt())">
                <textarea *ngSwitchCase="true" class="textarea" style="font-size:1em;" spellcheck="false" (paste)="onPaste()" (ngModelChange)="onTextAreaInputChange($event)" (mousedown)="$event.stopPropagation()"  [(ngModel)]="testState.notes"></textarea>
                <ng-container *ngSwitchCase="false">
                    <ng-container [ngSwitch]="!!isUsingTemporaryNotes()">
                        <textarea *ngSwitchCase="false" class="textarea" spellcheck="false" (ngModelChange)="onTextAreaInputChange($event)" (mousedown)="$event.stopPropagation()" oncopy="return false" [(ngModel)]="getActiveQuestionState().__meta.notes"></textarea>
                        <textarea *ngSwitchCase="true" class="textarea"  spellcheck="false" (ngModelChange)="onTextAreaInputChange($event)" (mousedown)="$event.stopPropagation()" oncopy="return false" [(ngModel)]="testState.notes"></textarea>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div *ngIf="isCalcAvailable()" style="z-index: 999;" class="calculator-container">
        <widget-calculator 
            [disableCalcSD]="disableCalcSD" 
            [disableCalcRoot]="disableCalcRoot" 
            [disableCalcDecimal]="disableCalcDecimal" 
            [enableScientificCalc]="enableScientificCalc"
            [disableCalcFraction]="disableCalcFraction" 
            [disableCalcFactorial]="disableCalcFactorial"
            [disableCalcABS]="disableCalcABS" 
            [disableCalcMOD]="disableCalcMOD"
            [disableCalcln]="disableCalcln" 
            [disableCalcLOGY]="disableCalcLOGY" 
            [disableCalcLOG10]="disableCalcLOG10"
            [disableCalcE]="disableCalcE" 
            [disableCalcEPOW]="disableCalcEPOW" 
            [disableCalcPI]="disableCalcPI" 
            [disableCalcPOW]="disableCalcPOW" 
            [disableCalcNTHROOT]="disableCalcNTHROOT" 
            [disableCalcONEOVERX]="disableCalcONEOVERX" 
            [disableCalcEXP]="disableCalcEXP" 
            [type]="getCalculatorType()" 
            [zoom]="getZoomValue()" 
            *ngIf="isShowingCalc() && resetter"
        ></widget-calculator>
    </div>

    <div *ngIf="isShowFormulaSheet" class="formula-container">
    <div class="formula-container__sheet">
        <div class="formula-container__sheet-header">
            <p>Formula Sheet</p>
            <button (click)="toggleFormula()"> <i class="fas fa-times"></i></button>
            
        </div>
    </div>
    </div>

    <div class="toolbar" [class.is-expanded]="isToolbarExpanded" [class.is-large-toolbar]="isLargeToolbar()" style="overflow-y:auto;" [style.padding-bottom.px]="getToolbarBottomPadding()">
        <div class="tools-top" #tooltipDiv>
            <button class="button collapser" (click)="toggleToolbar()">
                <i class="fa fa-angle-double-right" aria-hidden="true"></i>
            </button>
            <button class="toolbar-icon tool-help" [class.is-activated]="isHelpOverlay" (click)="toggleHelpScreen()" [tooltip]="lang.tra('btn_help')" placement="left"  *ngIf="isHelpEnabled && !isFlushNavigation()">
                <!-- <img src="https://mathproficiencytest.ca/img/text-to-speech.png"> -->
                <i class="fa fa-question-circle" style="font-size: 2em;" aria-hidden="true"></i>
            </button>
            <!-- Text to speech by Trevor Dsouza from the Noun Project -->
            <button class="toolbar-icon" [class.is-old-ff-browser]="isThisOldFirefoxBrowser()" (click)="toggleTextToSpeech()" [class.is-active]="isTextToSpeechActive()" *ngIf="isText2SpeechEnabled"  [class.is-button-disabled]="!isTTStoolEnabled()" [tooltip]="ttsTooltip()" placement="left">
                <img src="https://mathproficiencytest.ca/img/text-to-speech.png">
            </button>
            <button class="toolbar-icon" [class.is-old-ff-browser]="isThisOldFirefoxBrowser()" (click)="zoomIn()" [tooltip]="getZoomInTooltip()" placement="left" *ngIf="asmtFmrk.toolbarOptions.zoomIn">
                <img src="https://mathproficiencytest.ca/img/zoom-in.png">
            </button>
            <div style="color:#fff">{{getCurrentZoomDisplay()}}</div>
            <button class="toolbar-icon" [class.is-old-ff-browser]="isThisOldFirefoxBrowser()" (click)="zoomOut()" [tooltip]="getZoomOutTooltip()" placement="left" *ngIf="asmtFmrk.toolbarOptions.zoomOut">
                <img src="https://mathproficiencytest.ca/img/zoom-out.png">
            </button>
            <button class="toolbar-icon" [class.is-old-ff-browser]="isThisOldFirefoxBrowser()" (click)="toggleLineReader()" [class.is-active]="isLineReaderActive" [tooltip]="lang.tra(getLineReaderCaption())" placement="left" *ngIf="asmtFmrk.toolbarOptions.lineReader">
                <img src="https://www.mathproficiencytest.ca/img/line_reader.png">
            </button>
            <button *ngIf="isDrawingToolsShown() && asmtFmrk.toolbarOptions.hiContrast" [class.is-old-ff-browser]="isThisOldFirefoxBrowser()" class="toolbar-icon" (click)="toggleHiContrast()" [class.is-active]="isHighContrast" [tooltip]="lang.tra('btn_hi_contrast')" placement="left">
                <!-- hi contrast -->
                <img src="https://mathproficiencytest.ca/img/hi-contrast.png">
            </button>
            <button *ngIf="!isShowingResults && isDrawingToolsShown() && asmtFmrk.toolbarOptions.toggleEditor" class="toolbar-icon" (click)="setLine()" [class.is-active]="isShowOverlay && showLine" [class.is-inactive]="isShowingSectionInfo" [tooltip]="lang.tra('btn_toggle_editor')" placement="left">
                <i class="fas fa-edit" style="font-size: 2em; color:white;" aria-hidden="true"></i>
            </button>
            <button *ngIf="!isShowingResults && isDrawingToolsShown() && asmtFmrk.toolbarOptions.highlighter" class="toolbar-icon" (click)="setHighlighter()" [class.is-active]="isShowOverlay && showHighlight"  [class.is-inactive]="isShowingSectionInfo" [tooltip]="lang.tra('el_draw_highlighter')" placement="left">
                <i class="fas fa-highlighter" style="font-size: 2em; color:white;" aria-hidden="true"></i>
            </button>
            <button *ngIf="!isShowingResults && isDrawingToolsShown() && asmtFmrk.toolbarOptions.eraser" class="toolbar-icon" (click)="setEraser()" [class.is-active]="isShowOverlay && showEraser" [class.is-inactive]="isShowingSectionInfo" [tooltip]="lang.tra('btn_eraser')" placement="left" >
                <i class="fas fa-eraser" style="font-size: 2em; color:white;" aria-hidden="true"></i>
            </button>
            <button *ngIf="!isShowingResults && isNotepadAvailable() && asmtFmrk.toolbarOptions.notepad" class="toolbar-icon" (click)="toggleNotepad()" [class.is-active]="isNotepadEnabled" [tooltip]="lang.tra(getNotepadSlug())" placement="left" >
                <i class="fas fa-sticky-note" style="font-size: 2em; color:white;" aria-hidden="true"></i>
            </button>
            
        </div>
        <div class="tools-bottom">
            <button *ngIf="documentItems && documentItems.length && !checkIsOsslt()" 
                (click)="toggleDocuments()" 
                class="toolbar-icon" 
                [class.is-active]="isShowDocuments" 
                [tooltip]="getDocumentTooltip()" placement="left" 
                >
                <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/96360/authoring/reference-book/1631224536825/reference-book.png">
            </button>
            <!-- <button *ngIf="isFormulasAvailable()" (click)="toggleFormulas()" class="toolbar-icon" [class.is-active]="isFormulasToggledOn" title="{{lang.tra('btn_formulas')}}">
                <img src="https://mathproficiencytest.ca/img/reference-book.png">
            </button> -->
            <button *ngIf="isCalcAvailable()" (click)="toggleCalc()" class="toolbar-icon calc-button" [class.is-active]="isShowingCalc()" [tooltip]="lang.tra('btn_calculator')" placement="left">
                <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/UI_Elements/toobar/toolbar_icon-calc.svg">
            </button>
            <!-- <button *ngIf="isAllowToobarSizeAdjustment()" (click)="toggleSize()" class="toolbar-icon calc-button" [class.is-active]="isLargeToolbar()" [tooltip]="getToolbarSizeButtonTooltip()" placement="left">
                <img *ngIf="!isLargeToolbar()" style="filter: invert(100%)" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/format_font_size_increase_icon_137585/1677778123184/format_font_size_increase_icon_137585.png">
                <img *ngIf="isLargeToolbar()" style="filter: invert(100%)" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/format_font_size_decrease_icon_136597/1677782495120/format_font_size_decrease_icon_136597.png">

            </button> -->
            <button *ngIf="isAllowToobarSizeAdjustment() && !isLargeToolbar()" (click)="toggleSize()" class="toolbar-icon size-button" [tooltip]="getToolbarSizeButtonTooltip()" placement="left">
                <img style="filter: invert(100%)" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/format_font_size_increase_icon_137585/1677778123184/format_font_size_increase_icon_137585.png">
            </button>
            <button *ngIf="isAllowToobarSizeAdjustment() && isLargeToolbar()" (click)="toggleSize()" class="toolbar-icon size-button is-active" [tooltip]="getToolbarSizeButtonTooltip()" placement="left">
                <img style="filter: invert(100%)" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/format_font_size_decrease_icon_136597/1677782495120/format_font_size_decrease_icon_136597.png">
            </button>
        </div>

        <div *ngIf="!asmtFmrk.isOrale" (click)="toggleInfoModal()" class="info-button-toolbar" [tooltip]="lang.tra('btn_instructions')">
            <img style="height:50%;" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/96360/authoring/Info/1621008934819/Info.svg">
        </div>
    </div>

    <div class="info-overlay" *ngIf="isShowingReport">
        <div class="info-box">
            <strong><tra slug="btn_report_issue"></tra></strong>
            <hr/>
            <textarea class="textarea" placeholder="Please describe the issue that you experienced in the box below" [formControl]="issueReportMessage" rows=6></textarea>
            <hr/>
            <div class="buttons">
                <button class="button" (click)="sendIssueReport()">
                    Report Issue
                </button>
                <button class="button is-danger is-outlined" (click)="isShowingReport = false">
                    Cancel
                </button>
            </div>
        </div>
    </div>

    <div class="info-overlay" *ngIf="isShowDocuments">
        <div class="info-box is-maximized documents-container">
            <div [ngStyle]="{'font-size.em': getZoomValue()}">
                <div style="display:flex; flex-direction:row; justify-content: space-between;">
                    <menu-bar style="flex-grow:1" [menuTabs]="documentViews" (change)="selectDocumentView($event)" [tabIdInit]="selectedDocumentId"></menu-bar>
                    <a><i class="fas fa-times" style="font-size:1em;" (click)="isShowDocuments = !isShowDocuments"></i></a>
                    <div style="z-index:-1;position:absolute;left:46em;color:rgba(0,0,0,0)"><tra slug="ie_close_modal"></tra></div>
                </div>
                <div *ngIf="activeDocument">
                    <question-runner 
                        #questionRunner
                        [currentQuestion]="activeDocument" 
                        [containingWidth]="getQuestionRunnerWidth('main')"
                        [containingWidthSpill]="getQuestionRunnerSpillover('main')"
                        [allowSubtitles]="isAllowedSubtitles()"
                        [allowTranscripts]="isAllowedTranscripts()"
                        [allowAudioPlaybackSpeed]="isAllowedAudioPlaybackSpeed()"
                        [allowVideoPlaybackSpeed]="isAllowedVideoPlaybackSpeed()"
                        (manualSave)="manualSaveQuestion()"
                        [isFSA]="checkTag('BC_FSA')"
                    ></question-runner>
                </div>
            </div>
        </div>
    </div>

    <div class="info-overlay" *ngIf="isShowingTime">
        <div class="info-box">
            <small><tra slug="{{isBCED() ? 'test_time_message_bc' : 'test_time_message_bc'}}"></tra></small>
            <hr/>
            <div *ngIf="isBCED()">
                <div> <strong><tra slug="test_time_elapsed"></tra></strong> </div>
                <div class="test-time">{{getElapsedTestTime()}}</div>
                <ng-container *ngIf="asmtFmrk.recommendedTime">
                    <hr/>
                    <div><strong><tra slug="test_time_recommended"></tra></strong></div>
                    <div class="test-time" [innerHTML]="asmtFmrk.recommendedTime"></div>
                </ng-container>
                <ng-container *ngIf="asmtFmrk.extraTime">
                    <hr/>
                    <div><strong><tra slug="test_time_extra"></tra></strong></div>
                    <div class="test-time">{{asmtFmrk.extraTime}}</div>
                </ng-container>
            </div>
            <div *ngIf="!isBCED()">                
                <div> <strong><tra slug="test_time_remaining"></tra></strong> </div>
                <div style="font-size:1.4em;">{{regularTimeRemaining}}</div>
            </div>
            <!-- <hr/> -->
            <!-- <div> <strong>Extra Time Remaining:</strong> </div> -->
            <!-- <div>02:00</div> -->
            <hr/>
            <div>
                <button class="button is-fullwidth" (click)="isShowingTime=false">
                    OK
                </button>
            </div>
        </div>
    </div>

    <div class="chat-overlay" *ngIf="isShowingChat">
        <div class="info-box">
            <div>
                <button class="button is-fullwidth" (click)="isShowingChat=false">
                    Close
                </button>
            </div>
            <hr/>
            <div>
                <test-taker [uid]="auth.user().value.uid" [instit_group_id]="instit_group_id" [markingPoolId]="testSessionId" [chatService]="chatService"></test-taker>
            </div>
        </div>
    </div>

    <div class="save-overlay is-activated" [class.is-activated]="showSaving()" [class.inactive]="!showSaving()">
        <div class="overlay-bg"></div>
        <div class="overlay-text">
            <span class="tag is-warning"> <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>&nbsp; Saving...</span>
        </div>
    </div>

    <!-- <div style="top:0em; left:0em;" *ngIf="!isTestNavExpanded">
        <button class="button" (click)="toggleTestNav()">
            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
        </button>
    </div> -->

    <div class="expander" style="top:0em; right:0em;" *ngIf="!isToolbarExpanded">
        <button class="button" (click)="toggleToolbar()">
            <i class="fa fa-angle-double-left" aria-hidden="true"></i>
        </button>
    </div>

    <!-- *ngIf="isFlushNavigation()" -->
    <div class="navigation-panel-container" [class.is-wider]="lang.c() === 'fr'" >
        <div class="reading-text-controls" *ngIf="getActiveQuestionReadSel() && !isShowingResults && isShowingRight()">
            <button class="button has-icon" [style.display]="'initial'" (click)="readSelViewClose()">
                <span class="icon"><i class="fas fa-caret-square-right"></i></span>
                <span><tra slug="btn_readsel_close"></tra></span>
            </button>
            <button class="button has-icon" [style.display]="'initial'" (click)="readSelViewHalf()">
                <span class="icon"><i class="fas fa-columns"></i></span>
                <span><tra slug="btn_readsel_half"></tra></span>
            </button>
            <button class="button has-icon" [style.display]="'initial'" (click)="readSelViewFull()" *ngIf="!asmtFmrk.isOrale">
                <span class="icon"><i class="far fa-square"></i></span>
                <span><tra slug="btn_readsel_full"></tra></span>
            </button>
        </div>
        <button 
            *ngIf="isShowOverlay" 
            class="annotation-tool-close"
            (click)="removeAllDrawings()"
        >
            <tra slug="btn_remove_drawings"></tra>
        </button>
        
        <button 
            *ngIf="isShowOverlay" 
            class="annotation-tool-close"
            (click)="disableOverlay()"
        >
            <tra [slug]="getCloseDrawingSlug()"></tra>
        </button>
        <div class="navigation-panel" *ngIf="showPrevNext()  && isFlushNavigation()">
            <button class="toolbar-icon navigation-icon left-nav-button" (click)="gotoPrev()">
                <div class="nav-button-div">
                    <!--<i class="fas fa-arrow-left"></i> 
                    <tra slug="btn_prev_bc"></tra>-->
                    <!--"https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/96360/authoring/Reculer/1635783789537/Reculer.png"-->
                    <img [src]="getPrevSrcImg()" />
                    <div style="color:rgba(0,0,0,0);position:absolute;top: 2em;">
                        <tra *ngIf="whitelabel.getSiteFlag('IS_BCED')" slug="btn_prev_revenir_bc"></tra>
                        <tra *ngIf="!whitelabel.getSiteFlag('IS_BCED')" slug="btn_prev_bc"></tra>
                    </div>
                </div>
            </button>
            <button class="toolbar-icon navigation-icon" (click)="gotoNext()">
                <div class="nav-button-div">
                    <!--<i class="fas fa-arrow-right"></i> 
                    <tra slug="btn_next_bc"></tra>-->
                    <!--"https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/96360/authoring/Avancer/1635783738472/Avancer.png"-->
                    <img [src]="getNextSrcImg()"/>
                    <div style="color:rgba(0,0,0,0);position:absolute;top:2em;">
                        <tra slug="btn_next_bc"></tra>
                    </div>
                </div>
            </button>
        </div>
    </div>

</div>

<div *ngIf="isPrintMode && !isShowingResults" class="printed-test-form">
    <div>
        <strong> Test Form {{testFormId}}</strong>
        <!-- <div>The following sheets of paper must be shredded after digitization.</div> -->
    </div>
    <!-- section.isShuffleDisabled &&  !isShowQuestionLabel-->
    <div *ngFor="let section of testRunnerSections; let index = index" >
        <div class="section-block">
            <h1><tra [slug]="getSectionTitleSlug()"></tra> {{index+1}}</h1>
            <question-runner 
                #questionRunner
                [currentQuestion]="getSectionPreambleContent(section)" 
                [containingWidth]="getQuestionRunnerWidth('main')"
                [containingWidthSpill]="getQuestionRunnerSpillover('main')"
                [allowSubtitles]="isAllowedSubtitles()"
                [allowTranscripts]="isAllowedTranscripts()"
                [allowAudioPlaybackSpeed]="isAllowedAudioPlaybackSpeed()"
                [allowVideoPlaybackSpeed]="isAllowedVideoPlaybackSpeed()"
                (manualSave)="manualSaveQuestion()"
                [isFSA]="checkTag('BC_FSA')"
            ></question-runner>
        </div>
        <div class="question-block double-font-size" *ngFor="let question of section.questions; let qindex = index">
            <div style="border-top: 0.3em solid #666; padding:0.2em 0em;  font-weight:600; margin-top:1em;">
                <tra [slug]="getSectionTitleSlug()"></tra> {{index+1}}, Question {{qindex+1}}
                <span *ngIf="isShowQuestionLabel">[{{getQuestionLabel(question)}}]</span>
            </div>
            <question-runner 
                #questionRunner
                [currentQuestion]="getQuestionDef(question)" 
                [containingWidth]="getQuestionRunnerWidth('main')"
                [containingWidthSpill]="getQuestionRunnerSpillover('main')"
                [allowSubtitles]="isAllowedSubtitles()"
                [allowTranscripts]="isAllowedTranscripts()"
                [allowAudioPlaybackSpeed]="isAllowedAudioPlaybackSpeed()"
                [allowVideoPlaybackSpeed]="isAllowedVideoPlaybackSpeed()"
                (manualSave)="manualSaveQuestion()"
                [isFSA]="checkTag('BC_FSA')"
            ></question-runner>
        </div>
    </div>
</div>

<div *ngIf="isPrintMode && isShowingResults" class="printed-page" style="padding-left:0.5em; color:#000">

    <div class="dont-print" style="margin-top:1em;margin-bottom:1.5em;">
        <button class="button" (click)="exitExportResults()"> 
            <i style="margin-right:0.5em" class="fas fa-arrow-left"></i>
            <tra slug="btn_back"></tra>
        </button>
        <button class="button" (click)="triggerPrintDialog()"> 
            <i style="margin-right:0.5em" class="fas fa-print"></i>
            <tra slug="btn_print"></tra>
        </button>
    </div>

    <div>
        <div style="font-size:1.7em; line-height:1.3em; margin-bottom:0.5em;">
            <div [innerHtml]="getAssessmentName()"></div>
            <div><strong><tra slug="lbl_results_export"></tra></strong></div>
        </div>
        <div style="margin-bottom:0.8em;" *ngIf="showReportOptions(reportOptions.STUDENT_PEN)">
            <strong><tra slug="tr_student_pen"></tra> </strong>
            {{getTestTakerID()}}
        </div>
        <div style="margin-bottom:0.8em;">
            <tra slug="lbl_completed_on"></tra><tra slug="txt_colon"></tra>
            {{getWritingDate()}}
        </div>
    </div>

    <div style="border: 1px solid #000; margin-bottom:0.8em">
        <div style="    font-size: 1.2em; font-weight: 600; background-color: rgb(163, 188, 235); color: #000; padding: 0.2em;">
            <tra slug="tr_results_sr_questions"></tra> {{showReportOptions(reportOptions.SCORE)? '(points)' :''}}
        </div>
        <div style="display: flex; flex-direction: row; flex-wrap: wrap;;">
            <ng-container *ngFor="let questionId of resultQs">
                <div *ngIf="!isQuestionUnmarked(questionId)" [class.is-green]="isQuestionCorrect(questionId)" style="display: flex; flex-grow: 1; flex-direction:row; width:12em; justify-content: space-between; border:1px solid #000;    padding: 0.2em; margin-right: -1px;">
                    <div style="max-width:8em; overflow: hidden; text-overflow: ellipsis;">{{getQuestionTitleFromId(questionId)}}</div>
                    <div style="display: flex; flex-direction: row; align-self: flex-end; ">
                        <ng-container *ngIf="showReportOptions(reportOptions.CHECKMARK)">
                            <div style="margin-right: 0.5em;" [ngSwitch]="isQuestionCorrect(questionId)">
                                <i *ngSwitchCase="true" class="fas fa-check"></i>
                                <i *ngSwitchCase="false" class="fas fa-times is-red"></i>
                            </div>
                        </ng-container>
                        <div *ngIf="showReportOptions(reportOptions.SCORE)">({{getCorrectItemScore(questionId, true) }}/{{getQuestionTotalScore(questionId)}})</div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div style="padding: 0.2em" *ngIf="showReportOptions(reportOptions.SCORE)">
            <tra slug="tr_results_score_total"></tra> : {{finalReportStats.correctSRScore}}/{{finalReportStats.totalSRScore}}
        </div>
    </div>
    

    <div *ngIf="finalReportStats.numCRQuestions && finalReportStats.numCRQuestions>0" style="border: 1px solid #000; margin-bottom:0.8em">
        <div style="    font-size: 1.2em; font-weight: 600; background-color: rgb(163, 188, 235); color: #000; padding: 0.2em;">
            <tra slug="tr_results_cr_questions"></tra>
        </div>
        <div style="padding: 0.2em">
            <tra slug="lbl_num_responses"></tra><tra slug="txt_colon"></tra> 
            {{finalReportStats.numCRQuestions}}
        </div>
    </div>
    
    <div class="question-block double-font-size" *ngFor="let question of resultQs" >
        <ng-container *ngIf="isQuestionUnmarked(question) && getPrintElement(question)">
            <!-- <div style="border-top: 0.3em solid #666; padding:0.2em 0em;  font-weight:600; margin-top:1em; display: flex; flex-direction: column;"> -->
                <!-- <span>{{getQuestionTitleFromId(question)}}</span> -->
                <!-- <span *ngIf="!isQuestionUnmarked(question)"> -->
                    <!-- <tra slug="tr_results_score"></tra> {{getQuestionScore(question)}}/{{getQuestionTotalScore(question)}} -->
                <!-- </span> -->
                <!-- <span *ngIf="isQuestionUnmarked(question)"> -->
                    <!-- <tra slug="tr_results_score_pending"></tra> -->
                <!-- </span> -->
            <!-- </div> -->
            <div style="margin-bottom:0.8em; border:1px solid #ccc;">
                <question-runner
                    [currentQuestion]="getResultQuestionDef(question)"
                    [questionState]="getResultState(question)"
                    [containingWidth]="getQuestionRunnerWidth('main')"
                    [containingWidthSpill]="getQuestionRunnerSpillover('main')"
                    [isSubmitted]="true"
                    [isPrintMode]="true"
                    [allowSubtitles]="isAllowedSubtitles()"
                    [allowTranscripts]="isAllowedTranscripts()"
                    [allowAudioPlaybackSpeed]="isAllowedAudioPlaybackSpeed()"
                    [allowVideoPlaybackSpeed]="isAllowedVideoPlaybackSpeed()"
                    (manualSave)="manualSaveQuestion()"
                ></question-runner>
                <div style="font-size: 0.8em; text-align: right; padding: 0.5em; font-style: italic;">
                    <span>
                        <tra slug="lbl_submitted_by"></tra>  {{getTestTakerID()}}
                        (<tra slug="lbl_student_pen"></tra>)
                        <tra slug="snippet_for"></tra>
                    </span>&nbsp;
                    <span [innerHTML]="getAssessmentName(true)" ></span>
                </div>
            </div><br>
            <div *ngIf="getQuestionRubric(question)">
                <div class="pagebreak">
                </div>
                <element-render [contentElement]="getQuestionRubric(question)" isLocked=true>
                </element-render>
            </div>
        </ng-container>
    </div>
</div>

<div *ngIf="isShowingResults && pageMode === PageMode.TEST_RUNNER" class="score-legend-box">
    <div  class="score-box" *ngIf="showReportOptions(reportOptions.SCORE)">
        <div><tra slug="tr_results_score"></tra></div>
        <!-- getQuestionScoreDisplay(getActiveQuestionId()) -->
        <div *ngIf="!isQuestionUnmarked(getActiveQuestionId())">{{getCorrectItemScore(getActiveQuestionId(), true)}}/{{getQuestionTotalScore(getActiveQuestionId())}}</div>
        <!-- <img *ngIf="isQuestionUnmarked(getActiveQuestionId())" style="width: 1.1em" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6276/authoring/yellowclock/1612215246552/yellowclock.png"> -->
        <i *ngIf="isQuestionUnmarked(getActiveQuestionId())" class="fas fa-keyboard fa-fw" style="font-size: 1.1em" [style.color]="getKeyboardIconColor()"></i>
    </div>
    <ng-container *ngIf="showReportOptions(reportOptions.LEGEND)">
        <results-legend [numCRQuestions]="!!finalReportStats.numCRQuestions"></results-legend>
    </ng-container>
</div>