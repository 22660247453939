<div [ngSwitch]="optionElement.elementType" >
    <div *ngSwitchCase="'text'">
      <div *ngIf="isAdvTextEl(optionElement)" > 
        <element-render-text [element]="optionElement"></element-render-text>
      </div>
      <div *ngIf="!isAdvTextEl(optionElement)" [style.text-align]="alignLeft ? 'left' : 'center'" [style.color]="'black'" class="adv-text-container">
        <markdown-inline [isInvisible]="optionElement.isInvisible" [input]="optionElement.content"></markdown-inline>
      </div>
    </div>
    <div *ngSwitchCase="'audio'">
      <element-render-audio [element]="optionElement" [questionState]="questionState"></element-render-audio>
    </div>
    <div *ngSwitchCase="'table'">
      <element-render-table [element]="optionElement"></element-render-table>
    </div>
    <div *ngSwitchCase="'image'">
      <render-image *ngIf="isBasicImg(optionElement)" [element]="optionElement"></render-image>
      <element-render-image *ngIf="!isBasicImg(optionElement)" [element]="optionElement"></element-render-image>
    </div>
    <div *ngSwitchCase="'math'">
      <element-render-math [element]="optionElement" [changeCounter]="optionElement._changeCounter"></element-render-math>
    </div>
    <div *ngSwitchCase="'frame'">
      <element-render-frame [element]="optionElement"></element-render-frame>
    </div>
</div>   