import * as _ from 'lodash';
import { Component, OnInit, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
//import { QuestionState, IContentElement, IEntryStateInsertion, IContentElementDndTarget, ScoringTypes, IContentElementDndSub, IContentElementDndDraggable, getElementWeight, ElementType, IContentElementText, IContentElementInsertion, KeyboardDrop } from '../models';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { StyleprofileService, processText } from '../../core/styleprofile.service';
import { LangService } from '../../core/lang.service';
import { Subject } from 'rxjs';
import { IContentElementDndDraggable, IContentElementDndSub, KeyboardDrop, IElementPos } from '../element-render-dnd/model';
import { IContentElementText } from '../element-render-text/model';
import { ElementType, getElementWeight, QuestionState, ScoringTypes } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { TextToSpeechService } from "../text-to-speech.service";
import { IContentElementInsertion, IEntryStateInsertion } from './model';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';

const showInsertionInstr = (element: IContentElementInsertion) => {
  return element.isShowInstructions;
}
export const getInsertionInstrSlug = (element: IContentElementInsertion) => {
  if(!showInsertionInstr(element)) {
    return "";
  } 
  return element.isDragBetweenWords ? 'voice_insertion_blind_instr' : 'voice_insertion_instr';
}

interface IBlock {isTarget?: boolean, isPunctNext?:boolean, contents?:any[], isSpaceAfter?:boolean, str?:any, ctx?:IContentElementDndDraggable, renderedStr?: string};

@Component({
  selector: 'element-render-insertion',
  templateUrl: './element-render-insertion.component.html',
  styleUrls: ['./element-render-insertion.component.scss'],
})
export class ElementRenderInsertionComponent implements OnInit {

    @Input() element:IContentElementInsertion;
    @Input() isLocked: boolean;
    @Input() questionState: QuestionState;
    @Input() changeCounter: number;
    @Input() questionPubSub?: QuestionPubSub;

    draggables: IElementPos[] = [];
    dragTriggers: Map<any, Subject<boolean>> = new Map();
    // textBlocks: {textBlockContext: IContentElementDndTarget, contents?:IElementPos[]}[] = [];
    blocks: IBlock[]
    keyboardDrop: KeyboardDrop;

    constructor(
        private lang:LangService, 
        private profile:StyleprofileService,
        public textToSpeech:TextToSpeechService,
        // private accessibility:AccessibilityService,
        public whitelabel:WhitelabelService
    ) { }

    ngOnInit(): void {
      this.loadState();
      this.ensureState();
      this._resetKeyBoardSelection()
      if (!this.draggables) this.draggables = []

      if (this.element.useKeyIdOrOrderInFormatResponse === undefined && this.whitelabel.isBCED()){
        this.element.useKeyIdOrOrderInFormatResponse = true;
      }
    }
  
    ngOnChanges(changes: SimpleChanges) {
      if (changes.changeCounter){
        if (this.element.textBlocks!=undefined){ 
          this.updateDisplayEls();
        }
      }
    }

    getInstrSlug() {
      return getInsertionInstrSlug(this.element);
    }

    showInstr() {
      return showInsertionInstr(this.element);
    }

    isDragBetweenWords(){
      return this.element && this.element.isDragBetweenWords;
    }

    ensureState(){
      if (this.questionState) {
        if (!this.questionState[this.element.entryId]) {
          console.log("initializing state");
          let entryState: IEntryStateInsertion = {
            type: ElementType.INSERTION,
            isCorrect: false,
            isStarted: false,
            isFilled: false,
            isResponded: false,
            score: 0, //this.targets.find((target:any) => target.groups.length) ? getElementWeight(this.element) : 0,
            weight: getElementWeight(this.element),
            scoring_type: ScoringTypes.AUTO,
            draggables: this.draggables,
            targets: this.blocks,
            useKeyIdOrOrderInFormatResponse: this.element.useKeyIdOrOrderInFormatResponse
          };
          //console.log("initializing state")
          this.questionState[this.element.entryId] = entryState;
        }
        
      }
    }

    loadState() {
      if (this.questionState && this.questionState[this.element.entryId]) {
        console.log("loading prior state")
        this.draggables = this.questionState[this.element.entryId].draggables;
        this.blocks = this.questionState[this.element.entryId].targets;
      } else {
        console.log("updating display els")
        this.updateDisplayEls();
      }
    }

    draggableMargin=0.25;
    getMargin(){
      return `${this.draggableMargin}em`
    }
    isUsingNumColumns(isContainer = false) {
      // ($dw * $ncols) + (5*$dm * $ncols)
      if(isContainer && this.whitelabel.isBCED() && this.element.numColumns && this.element.numColumns > 0 && this.element.blankTargetSize && this.element.blankTargetSize > 0) {
        return `${(this.element.blankTargetSize * this.element.numColumns) + (5*this.draggableMargin*this.element.numColumns)}em`
      }else if(!isContainer && this.element.blankTargetSize && this.element.blankTargetSize > 0) {
        return `${this.element.blankTargetSize}em`
      } else {
        return null;
      }
    }

    updateDisplayEls() {
      console.log("updateDisplay")
      this.draggables = [];
      if (!this.element.draggables || !this.element.textBlocks) return;
      this.element.draggables.forEach(element => this.addElementToList(element, this.draggables));

      this.blocks = [];
      this.element.textBlocks.forEach(ctx => {
        if (ctx.element && ctx.element.elementType === ElementType.TEXT){
          const textElement = <IContentElementText> ctx.element;
          (textElement.caption || '').split(/\s/).forEach((str,i) => {
            if (this.isDragBetweenWords()){
              //console.log(textElement.caption)
              this.blocks.push({isTarget:true, contents:[]});
            }else{
              this.blocks.push({str, isSpaceAfter:!this.isDragBetweenWords(), ctx});
            }
          });
        }
        else {
          const last_i = this.blocks.length -1;
          const lastBlock = this.blocks[last_i];
          if (lastBlock) {
            if (lastBlock.isTarget && !lastBlock.ctx){
              this.blocks.splice(last_i, 1);
            }
            else{
              lastBlock.isSpaceAfter = false;
            }
          }
          
          //console.log(lastBlock.caption)
          this.blocks.push({isTarget:true, contents:[], ctx })
        }
      })
      // const caption = `Aunt Cathie had arrived, and was resting in her bedroom at Brayton before dressing for dinner. Lucia was coming up for a chat later, but Aunt Cathie was glad to be alone for a little, and recover from the excitement and strangeness of it all.`;

    }
  
    addElementToList(element: IContentElementDndSub, elementsToPosition: IElementPos[], isTarget: boolean= false) {
      let hasElement  = false;
      if ((<IContentElementDndDraggable> element).element) {
        hasElement = true;
      }
      elementsToPosition.push({
        ref: element,
        originalX: element.x,
        originalY: element.y,
        isTarget,
        //style: renderDndElementStyle(element, hasElement, isTarget && this.element.customTargetDim, this.element.defaultTargetStyle),
        style: {}
      });
      return
    }
  
    refreshState(){
      
      if (this.questionState) {
        const entryId = this.element.entryId;
        const weight = getElementWeight(this.element);
        let score = weight;
        let missed = 0;
        let correct = 0;
        let isFilled = true;
        let isStarted = false;
        if (!this.element.isDragBetweenWords) {
          this.blocks.forEach((block)=>{
            if (block.isTarget) {
              if (block.contents && block.contents.length>0) {
                const droppedObj = block.contents[0].ref;
                if (!block.ctx || droppedObj.id != block.ctx.id) {
                  missed++;
                } else {
                  correct++;
                }
                isStarted = true;
              } else {
                missed++;
                isFilled=false;
              }
            }
          })
        } else {
            this.blocks.forEach((block)=>{
              if (!block.isTarget) return;
              if (block.contents.length>0) {
                if (!block.ctx) missed++;
                else {
                  const droppedObj = block.contents[0].ref;
                  if (droppedObj.id != block.ctx.id) missed++;
                  else correct++;
                }
                isStarted = true;
              } else {
                if (block.isTarget && block.ctx) {
                  missed++;
                  isFilled=false;
                }
              }
            })
        }

        if (this.element.enableProportionalScoring) {
          const ratio = correct/(missed+correct);
          score = ratio*weight;
        } else if (missed>0) {
          score = 0;
        } else {
          score = weight;
        }
        //console.log(score);

        let isResponded = this.questionState[this.element.entryId].isResponded || isStarted;
        let entryState: IEntryStateInsertion = {
          type: ElementType.INSERTION,
          isCorrect: score==weight,
          isStarted: isStarted, //this.targets.find((target:any) => target.groups.length) ? true : false,
          isFilled: isFilled, //this.targets.find((target:any) => target.groups.length) ? true : false,
          isResponded,
          score: score, //this.targets.find((target:any) => target.groups.length) ? getElementWeight(this.element) : 0,
          weight: getElementWeight(this.element),
          scoring_type: ScoringTypes.AUTO,
          draggables: this.draggables,
          targets: this.blocks, // this.textBlocks
          useKeyIdOrOrderInFormatResponse: this.element.useKeyIdOrOrderInFormatResponse
        };
        if (this.questionState){
          this.questionState[this.element.entryId] = entryState;
        }
      }
    }
  
    private _resetKeyBoardSelection(){
      this.keyboardDrop = {
        lastSrcIndex: null,
        sourceElement: {},
        source: [],
        srcFromHome: null
      } 
    }

    private _setKeyBoardSelection(container, elPos, idx, isHome){
      this.keyboardDrop.source = container
      this.keyboardDrop.sourceElement = elPos
      this.keyboardDrop.lastSrcIndex = idx
      this.keyboardDrop.srcFromHome = isHome ? true : false
    }
  
    isSelected(element,container){
      return this.keyboardDrop.source.length && this.keyboardDrop.source === container && this.keyboardDrop.sourceElement === element
    }

    onEnter(e, elPos: IElementPos | {}, container: IElementPos[], idx: number, isHome: boolean){
      e.stopPropagation();
      // console.log("element", container, idx )

      if ((!_.isEmpty(elPos) && elPos !== undefined) && !this.keyboardDrop.source.length){
        if(!isHome) this.playTargetAudio(<IElementPos>elPos)
        this._setKeyBoardSelection(container, elPos, idx, isHome)
        return
      } 
      
      if(this.keyboardDrop.source === container){
        if(this.keyboardDrop.sourceElement === elPos){
          this._resetKeyBoardSelection();
          return;
        }
        // If not same element select the new one of the same container
        this._setKeyBoardSelection(container, elPos, idx, isHome)
        return;
      }

      //if the transfer is in dest -> dest. Idx will be for container -> change it to the 1st element.
      if(!_.isEmpty(elPos) && !this.keyboardDrop.srcFromHome){
        idx = 0
        this.keyboardDrop.lastSrcIndex = 0
      }
      
      // Drop to dest container:
      
      const {source: previousContainer, lastSrcIndex:previousIndex} = this.keyboardDrop
      this._drop({previousContainer, container, previousIndex, currentIndex: idx})
      
    }
  
    drop(event: CdkDragDrop<string[]>, isHomeDest:boolean=false) {
      this._drop({
        previousContainer: event.previousContainer.data,
        container: event.container.data,
        previousIndex: event.previousIndex,
        currentIndex: event.currentIndex
      });
    }

    private _drop(dropLocation){
      const {previousContainer, container, previousIndex, currentIndex} = dropLocation
      const isComingFromHome = previousContainer === this.draggables;
      const isDestToHome = container === this.draggables;

      if (this.isDragBetweenWords()){
        
        // console.log({isComingFromHome, isDestToHome});
        if (isDestToHome){
          if (!isComingFromHome){
            transferArrayItem(previousContainer,
              [],
              previousIndex,
              0);
          }
        }
        else{
          if (isComingFromHome){
            // transferArrayItem(event.previousContainer.data, [], event.previousIndex, 0);
            copyArrayItem(previousContainer,
              container,
              previousIndex,
              currentIndex);
            
          } else {
            transferArrayItem(previousContainer,
              container,
              previousIndex,
              currentIndex);
          }
        }
      } else{
        let allowTransfer = true;
        if (!isComingFromHome && !isDestToHome) {
          if (container.length>0) {
            transferArrayItem(container,
              previousContainer,
              currentIndex,
              previousIndex+1);
          }
          transferArrayItem(previousContainer,
            container,
            previousIndex,
            currentIndex);
        } else if (!isComingFromHome && isDestToHome) {
          if (!this.element.isRepeatableOptions) {
            transferArrayItem(previousContainer,
              container,
              previousIndex,
              currentIndex);
          } else {
            transferArrayItem(previousContainer,
              [],
              previousIndex,
              0);
          }
        } else if (isComingFromHome && !isDestToHome) {
          if (!this.element.isRepeatableOptions) {
            if (container.length>0) {
              transferArrayItem(container,
                previousContainer,
                currentIndex,
                previousIndex+1);
            }
            transferArrayItem(previousContainer,
              container,
              previousIndex,
              currentIndex);
          } else {
            if (container.length>0) {
              transferArrayItem(container,
                [],
                currentIndex,
                0);
            }
            copyArrayItem(previousContainer,
              container,
              previousIndex,
              currentIndex);
          }
          
        }
      }

      this.refreshState();
      this.rearrangeByOrder();
      this.removeSelection();
      this._resetKeyBoardSelection();
    }
  
    hasSuper(block: IBlock) {
      let str = block.renderedStr || block.str;
      return str?.includes('<sup>');
    }

    removeSelection() {
      if (window.getSelection()) {
        window.getSelection().removeAllRanges()
      } else if (document.getSelection()) {
        document.getSelection().removeAllRanges();
      }
    }

    rearrangeByOrder() {
      const configDraggables = this.element.draggables;
      const newDraggables = []
      configDraggables.forEach((opt)=>{
        const parallelDraggable = this.draggables.find(o => (<IContentElementDndDraggable>o.ref).id === opt.id)
        if(parallelDraggable) {
          newDraggables.push(parallelDraggable);
        }
      })
      this.draggables = newDraggables;
    }

    updateRender(str:string){
      const lang = this.lang.c();
      return processText(str, this.profile.getStyleProfile()[lang].renderStyling.plainText.transforms);
    }

    isDragWidthFixed() {
      return this.element.isDragContainerFixedWidth && this.element.widthOfDraggables
    }

    getDragWidthSet() {
      let style = {}
      if (this.isDragWidthFixed()) {
        style['min-width.em']=this.element.widthOfDraggables;
        style['max-width.em']=this.element.widthOfDraggables;
        style['width.em']=this.element.widthOfDraggables;
        style['flex-wrap']='wrap'
      } else {
        style['flex-wrap']='nowrap'
      }
      return style
    }
  isVoiceoverEnabled() {
    return this.textToSpeech.isActive;
  }

  getElementAudio(voiceover: any): string {
    return (voiceover && voiceover.url) ? voiceover.url : '';
  }

  getAudioTrigger(element: any){
    let trigger = this.dragTriggers.get(element);
    if (!trigger){
      trigger = new Subject();
      this.dragTriggers.set(element, trigger);
    }
    return trigger;
  }

  playTargetAudio(element: any) {
    if(!element) {
      return;
    }
    let elRef = element.ref || element;
    const voiceover = elRef.voiceover
    if (voiceover && voiceover.url) {
      this.getAudioTrigger(element).next(true);
    }
  }

  getTargetElementMinHeight() {
    const defaultMinHeight = this.isDragBetweenWords() ? 0 : 1.6; 
    return this.element.targetGroupLh ?? defaultMinHeight;
  }

  onClickToDrag(e, elPos: IElementPos | {}, container: IElementPos[], idx: number, isHome: boolean){
    return;
    // if(!this.accessibility.clickToDragDrop) {
    //   return;
    // }
    // this.onEnter(e, elPos, container, idx, isHome);
  }
  
}