import { Component, OnInit, Input, ElementRef, ViewChild, ChangeDetectorRef, OnDestroy, SecurityContext} from '@angular/core';
import { IContentElementVideo, IEntryStateVideo, QuestionState, ElementType } from '../models';
import {BrowserModule, DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'
import { LangService } from '../../core/lang.service';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { HttpClient } from '@angular/common/http';
import { AuthService, getFrontendDomain } from '../../api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';
import { ZoomService } from '../../ui-testrunner/zoom.service';
import { ActivatedRoute } from "@angular/router";
import { videoPlaybackService } from '../video-playback.service';


export const memo = <T>(map: Map<string, T>, key:string, generator: (key:string) => T ) => {
  if (map.has(key)){
    return map.get(key);
  }
  else{
    const val = generator(key);
    map.set(key, val);
    return val;
  }
}

export const typesFromExt = {mp4: 'video/mp4', webm: 'video/webm', ogg: 'video/ogg', mov: 'video/mp4'};

@Component({
  selector: 'element-render-video',
  templateUrl: './element-render-video.component.html',
  styleUrls: ['./element-render-video.component.scss']
})
export class ElementRenderVideoComponent implements OnInit, OnDestroy {

  @Input() element:IContentElementVideo;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() allowSubtitles: boolean = false;
  @Input() allowTranscripts: boolean = false;
  @Input() allowPlaybackSpeed: boolean = false;

  @ViewChild('video')  video: ElementRef
  @ViewChild('videoTooltip')  videoTooltip: ElementRef
  @ViewChild('videoPlayer') videoPlayer: ElementRef

  isTooltipVisible: boolean = false; 
  public plyrOptions = {
    controls: [
      'play-large', 
      'play', 
      'progress', 
      'current-time', 
      'mute', 
      'volume', 
      'captions', 
      'settings', 
      'fullscreen'
    ]
  }
  private sanitizedUrls:Map<string, SafeResourceUrl> = new Map();
  private videoSources:Map<string, any[]> = new Map();
  private subtitleTracks:Map<string, any[]> = new Map();
  public videoSourceUrls:string[] = [];


  constructor(
    private sanitizer: DomSanitizer,
    private changeDetector: ChangeDetectorRef,
    private whitelabel: WhitelabelService,
    private http: HttpClient,
    private auth: AuthService,
    private routes: RoutesService,
    public zoom: ZoomService,
    public lang: LangService,
    private route: ActivatedRoute,
    private playback: videoPlaybackService,
  ) { }

  isAutoPlay = false;
  startTimeStamp = 0;
  isLimitted:boolean;
  playBackRate = 1.0
  playBackRates = [
    1.0, 0.9, 0.75, 0.5, 0.25
  ]
  playBackRate_fr = '1,0'
  playBackRates_fr = [
    '1,0', '0,9', '0,75', '0,5', '0,25'
  ]

  transcriptUrl
  accommodation
  videoEndTimeOut
  ngOnInit() {
    if (this.element.assetId) {
      this.auth.apiGet(this.routes.TEST_AUTH_ASSET, this.element.assetId).then((res)=>{
        if (!res) {
          return
        }
        if (this.lang.getCurrentLanguage()=='en' && res["transcript_en"]) {
          this.transcriptUrl = res["transcript_en"]
        } else if (this.lang.getCurrentLanguage()=='fr' && res["transcript_fr"]) {
          this.transcriptUrl = res["transcript_fr"]
        }
      })
    } else {
      this.transcriptUrl = this.element.transcriptUrl
    }
    this.isPlaying = false;
    this.isLimitted = !(this.whitelabel.getSiteFlag('IS_EQAO') && !this.element.isRestricted);
    this.ensureState();
    this.initVars();

    if (this.element.isAutoPlay) {
      this.initVideoCountdown();
    }

    if (!this.element.enableVideoControls) {
      this.element.enableVideoControls = false;
    }

    if (!this.element.mustWatch) {
      this.element.mustWatch = false;
    }

    if (!this.element.markFilledWhenEnd) {
      this.element.markFilledWhenEnd = false;
    }

    this.accommodation = this.route.snapshot.queryParamMap.get('isAccomm')

    // Wait 3 seconds for the component to finish initialzing, then check if all views have been used,
    // if the video cannot be played anymore, trigger the element pubsub that is tied to the video end event.
    this.videoEndTimeOut = setTimeout(()=>{
      this.checkAndTriggerElementPub()
    }, 3000)
  }

  initVars() {
    const id = this.element.entryId
    if (this.questionState){
      if (this.questionState[id]) {
        const qState = this.questionState[id]
        if (!qState.seekTimes) {
          qState.seekTimes = []
        }
        if (!qState.loadTimes) {
          qState.loadTimes = []
        }
        if (!qState.pauseTimes) {
          qState.pauseTimes = []
        }
        if (!qState.endedTimes) {
          qState.endedTimes = []
        }
      }
      this.questionState[id].loadTimes.push(this.getTime())
    }

    this.videoSourceUrls = this.getVideoSources();
  }

  ngAfterViewInit() {
    
  }

  getCleanURL(url) {
    if (!url) return;
    const newURL = this.sanitizer.bypassSecurityTrustResourceUrl(url)
    return newURL
  }

  transcriptOn = false;
  transcript = "";
  showTranscript() {
    if (this.transcriptUrl) {
      this.transcriptOn = !this.transcriptOn;
    } 

    this.http.get(this.transcriptUrl).subscribe(res => {
      if (!res["text"]) return
      this.transcript = res["text"]
    });
  }

  adjustRate() {
    const el = this.getVideoElement()
    if (el) {
      el.playbackRate = this.playBackRate
    }
  }

  ngOnDestroy() {
    // console.log('destroyed')
    try{
      clearInterval(this.countdownInterval)
      const el = this.getVideoElement();
      // console.log('el to destroy', el)
      el.pause();
    } catch(e) {}
  }

  isCountdownInProgress: boolean;
  countdownInterval: any;
  countdownTimeRemaining: number;
  initVideoCountdown() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
    this.isCountdownInProgress = true;
    this.countdownTimeRemaining = this.element.autoPlayDelay;
    this.countdownInterval = setInterval(() => {
      if (!this.isCountdownInProgress || this.isLocked) {
        return;
      }
      if (this.countdownTimeRemaining > 0) {
        this.countdownTimeRemaining--;
      }
      if (this.countdownTimeRemaining <= 0) {
        this.isCountdownInProgress = false;
        this.play();
      }
    }, 1000);
  }


  canStartPlayingCalled = false;
  canStartPlaying() {
    if (!this.canStartPlayingCalled && this.questionState && this.canPlay()) {
      const id = this.element.entryId
      const el = this.getVideoElement()
      const ts = this.questionState[id].timeStamp;
      this.startTimeStamp = ts;
      if (!el) return;
      if (this.element.allowPlaybackSpeedAdjustment && !this.playback.isUniversalPlaybackSpeedDisabled){
        el['playbackRate'] = this.playback.currentUniversalPlaybackSpeed;
      }
      if (ts && ts>0 && ts<el["duration"] && !this.element.doNotResumePlayingWhenReturn) {
        this.isAutoPlay = true;
        el["currentTime"] = ts;
        el.play();
      }
      else if (ts && ts>0 && ts<el["duration"] && this.element.doNotResumePlayingWhenReturn) {
        el["currentTime"] = ts;
      }
      this.canStartPlayingCalled = true;
    }
    
  }

  resetter = true;
  resetFlag() {
    this.resetter = false;
    this.changeDetector.detectChanges();
    this.resetter = true;
  }

  getTime() {
    const d = new Date();
    return d.getTime();
  }

  getVideoElement() {
    // return this.video.nativeElement
    const id = this.element.entryId
    let el = null;
    
    if (this.videoPlayer && this.videoPlayer.nativeElement) {
      el = this.videoPlayer.nativeElement
    }
    return el;
  }

  ensureState() {
    if (this.questionState && !this.questionState[this.element.entryId]) {
      const state:IEntryStateVideo = {
        isStarted: true,
        isFilled: true,
        isResponded: false,
        numViews: 0,
        timeStamp: 0,
        type: ElementType.VIDEO
      };
      if (!state.numViews) {
        state.numViews = 0;
      }
      this.questionState[this.element.entryId] = state;
    }
    if (this.questionState){
      if (this.element.mustWatch && this.questionState[this.element.entryId].numViews < 1 ){
        this.questionState[this.element.entryId].isFilled = false
      }
      return this.questionState[this.element.entryId];
    }
    else{
      return {}
    }
  }

  private isSafari(){
    const userAgent = window.navigator.userAgent;
    return userAgent.indexOf("Safari") > -1;
  }
  
  loadedMetaData() {
    if (this.isSafari()) {
      this.canStartPlaying()
    }
  }


  getSecRemainingStr() {
    const element = this.getVideoElement();
    if (!element) {
      return '';
    }

    const leadingZero = (n:number) => {
      if ((''+n).length < 2){
        return '0'+n
      }
      return ''+n
    }
    const convertSecToMinSec = (sec) => {
      if (!sec ){
        return '00:00'
      }
      const min = Math.floor(sec/60);
      return leadingZero(min)+':'+leadingZero(Math.floor(sec - min*60))
    }

    const remainingSeconds = () => {
      return (element["duration"] - element["currentTime"]) | 0
    }
    // return convertSecToMinSec(element["currentTime"]) + ' / ' + convertSecToMinSec(element["duration"])
    return remainingSeconds()
  }

  hasStarted() {
    return this.isPlaying
  }

  getSubtitleTracks(){
    return memo(this.subtitleTracks, this.element.subtitlesUrl, src => {
      return [{
        kind: 'subtitles',
        label: this.lang.getCurrentLanguage() == 'fr' ? 'French': 'English',
        srclang: this.lang.getCurrentLanguage(),
        src: src ? this.optimizeUrl(src) : '',
        default: true,
      }]
    });
  }

  getVideoSources(){
    console.log('getVideoSources', this.element.urls)
    if(this.element.urls && this.element.urls.length > 0) {
      return this.optimizeTypes(this.element.urls);
    }
    // return memo(this.videoSources, this.element.url, src => [{ src: this.optimizeUrl(src), type: 'video/mp4' }]);
    return this.optimizeTypes([this.element.url]);
  }

  optimizeTypes(urls:string[]) {
    const optimized = [];
    
    for (let i = 0; i < urls.length; i++) {
      const src = urls[i];
      const ext = src.split('.').pop().toLowerCase();
      const type = typesFromExt[ext];

      if (type) {
        optimized.push({ src: (ext == "mp4" ? this.optimizeUrl(src) : src), type });
      }
    }

    console.log('optimized', optimized)

    return optimized
  }

  optimizeUrl(url:string){
    const domainRaw:string = 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/';
    const domainCdn:string = 'https://d3azfb2wuqle4e.cloudfront.net/';
    return url.split(domainRaw).join(domainCdn);
  }

  getVideoUrl(){
    return memo(this.sanitizedUrls, this.element.url, rawUrl => this.sanitizer.bypassSecurityTrustResourceUrl(this.optimizeUrl(rawUrl)) );
  }

  isPlaying;
  playing() {  
    if (!this.isPlaying){
      this.addView()
    }
    this.isPlaying = true;
    this.questionState[this.element.entryId].isResponded = true;
    
    if(this.questionState && this.questionState[this.element.entryId].numViews === 0) {
      this.isTooltipVisible = true;
    }
  }
  
  fullScreen() {
    const elem = this.getVideoElement()
    if (elem && this.element.allowFullScreen) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
    }
  }

  play() {
    this.isPlaying = true;
    this.isAutoPlay = false;
    const el:HTMLMediaElement = this.getVideoElement();
    if (!el) return;
    if (this.canPlay()) {
      el.play();
      this.paused? '': this.addView();
      this.paused = false
    }
  }

  paused = false
  pause() {
    const el:HTMLMediaElement = this.getVideoElement();
    if (!this.element.isUnPausable) {
      this.paused = true;
      el.pause();
    }
  }

  notPlaying(logTime:boolean = true) {
    this.isPlaying = false;
    this.isAutoPlay = false;
    if (logTime) {
      if (this.questionState && this.questionState[this.element.entryId]) {
        this.questionState[this.element.entryId].pauseTimes.push(this.getTime());
      }
    }
    // console.log("pausing times", this.questionState[this.element.entryId].pauseTimes)
  }

  addView() {
    if (this.questionState && this.questionState[this.element.entryId]) {
      const state = this.questionState[this.element.entryId]
      const el = this.getVideoElement();
      if (!el) return;
      if (!this.isAutoPlay && (!state.timeStamp || state.timeStamp==0 || state.timeStamp==el["duration"])){
        state.numViews++;
        clearTimeout(this.videoEndTimeOut); // clear the timeout and leave the pubsub to be triggered by the normal video end event.
        (this.element.mustWatch && state.numViews >= 1 && !this.element.markFilledWhenEnd) ? state.isFilled = true : '';
        state.timeStamp = 0;
        this.resetFlag();
      }
    }
  }

  ended() {
    this.notPlaying(false);
    if (this.questionState && this.questionState[this.element.entryId]) {
      this.questionState[this.element.entryId].endedTimes.push(this.getTime());
      this.questionState[this.element.entryId].isFilled = true;
    }
    this.checkAndTriggerElementPub();
    if (this.element.loopAutomatically) {
      this.play()
    }
  }

  canPlay() {
    const state = this.ensureState();
    const el = this.getVideoElement()
    if (!el) {
      return false;
    }
    if (state){
      if (this.element.maxViews && ((state["timeStamp"] == 0 || Math.abs(state["timeStamp"].toFixed(3) - el["duration"].toFixed(3)) <= 0.1)&& state.numViews >= this.element.maxViews)){
        return false;
      }
      else{
        return true;
      }
    }
    return false;  
  }

  dismissTooltip() {
    this.isTooltipVisible = false;
  }

  timeSet = false;
  onTimeUpdate() {
    if (this.questionState && this.questionState[this.element.entryId]) {
      const element = this.getVideoElement();
      if (!element) return;
      const state = this.questionState[this.element.entryId]
      if (state.timeStamp && state.timeStamp > element["currentTime"] && !this.timeSet) {
        element["currentTime"] = state.timeStamp
        this.timeSet = true
      } else {
        state.timeStamp = element["currentTime"]
      }
    }
  }

  onSeeking() {
    if (this.questionState && this.questionState[this.element.entryId]) {
      if (!this.questionState[this.element.entryId].seekTimes) {
        this.questionState[this.element.entryId].seekTimes = []
      }
      this.questionState[this.element.entryId].seekTimes.push(this.getTime())
    }
  }

  getScreenShrinkZoom() {
    return this.zoom.getScreenShrinkZoom();
  }

  getZoomLevel() {
    return this.zoom.getZoom();
  }

  defaultZoomLevel = 1.5;
  getZoom() {
      const ua = navigator.userAgent;
      if (ua.indexOf("iPad")!=-1 || ua.indexOf("iPhone")!=-1) {
        if (this.getZoomLevel() > this.defaultZoomLevel) {
          this.zoom.update(this.defaultZoomLevel);
        }
      }
      return this.getScreenShrinkZoom();
  };

  getVideoControlList(){
    if(this.element.enableVideoControls && this.element.allowPlaybackSpeedAdjustment){
      return "nodownload nofullscreen noremoteplayback"
    }
    return "nodownload nofullscreen noremoteplayback noplaybackrate"
  }

  onPlaybackSpeedChange(){
    console.log(this.getVideoElement()['playbackRate']);
    if (this.getVideoElement() && this.getVideoElement()['playbackRate'] && !this.playback.isUniversalPlaybackSpeedDisabled){
      this.playback.currentUniversalPlaybackSpeed = this.getVideoElement()['playbackRate'];
    }
  }

  hideVideoControlPlayButton(){
    return !this.isLimitted || (this.isPlaying && this.element.isUnPausable);
  }

  hideVideoControlTimeline(){
    return !this.isLimitted || this.element.isUnPausable;
  }

  /**
   * Check if video has video end event, and if all views are used. If so, trigger the pubsub.
   */
  checkAndTriggerElementPub(){
    if (this.element.onVideoEnd && (this.questionState[this.element.entryId].numViews >= this.element.maxViews)) {
      this.element.onVideoEnd.forEach(pub => {
        this.questionPubSub.elementPub(+pub.entryId, pub.type, pub.data);
      });
    }
  }

  isFrench() {
    return this.lang.c() === 'fr'
  }

  adjustRate_fr(){
    const el = this.getVideoElement()
    if (el) {
      let playBackRate_fr_number = +this.playBackRate_fr.replace(',', '.')
      el.playbackRate = playBackRate_fr_number;
    }
  }

  getSecondsRemainingSlug(){
    if (this.whitelabel.getSiteFlag('IS_BCED'))
      return 'lbl_num_secs_remaining_BCED';

    return 'lbl_num_secs_remaining';
  }
}
