import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';
import { TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { DistrictDetail, SchoolDetail } from "../../bc-accounts/bc-accounts.service";
import { calculateRatio, parseWindowCode } from '../../utils';

@Component({
  selector: 'fsa-proficiency-level-report',
  templateUrl: './fsa-proficiency-level-report.component.html',
  styleUrls: ['./fsa-proficiency-level-report.component.scss']
})
export class FsaProficiencyLevelReportComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private routes: RoutesService,
  ) { 

  }

  @Input() testWindow: TestWindow;
  @Input() districtDetail: DistrictDetail;
  @Input() schoolDetail: SchoolDetail;
  @Input() showHeader: boolean = false;

  public isLoading: boolean = true;
  public proficiencyLevels = {
    1: "Emerging",
    2: "On Track",
    3: "Extending",
  };

  public chartLabels = ["Emerging","On Track","Extending"];
  public literacyGrade4Data = [];
  public literacyGrade7Data = [];
  public numeracyGrade4Data = [];
  public numeracyGrade7Data = [];

  public data = [
    {
      grade: "4",
      subject: "literacy",
      registrations: 0,
      numbers: [],
    },
    {
      grade: "4",
      subject: "numeracy",
      registrations: 0,
      numbers: [],
    },
    {
      grade: "7",
      subject: "literacy",
      registrations: 0,
      numbers: [],
    },
    {
      grade: "7",
      subject: "numeracy",
      registrations: 0,
      numbers: [],
    }
  ];

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);

    this.fetchData();
  }

  ngOnInit(): void {
  }

  private resetData() {
    this.literacyGrade4Data = [];
    this.literacyGrade7Data = [];
    this.numeracyGrade4Data = [];
    this.numeracyGrade7Data = [];
  }

  public async fetchData() {
    if (!this.testWindow) return;
    if (!(this.districtDetail || this.schoolDetail)) return;

    this.isLoading = true;

    this.resetData();

    for (const grade of ['4', '7']) {
      const res = await this.auth.apiFind(this.routes.BCED_PROFICIENCY_LEVEL, {
        query: {
          testWindowId: this.testWindow.id, 
          grade,
          schoolGroupId: this.schoolDetail?.groupId, 
          districtGroupId: this.districtDetail?.groupId,
        }
      });

      for (const subject of ['literacy', 'numeracy']) {
        const row = this.data.find(item => item.grade === grade && item.subject === subject);
  
        if (row) {
          row.registrations = res.registrations;

          row.numbers = res[subject]
            .sort((a,b) => a.proficiency_level - b.proficiency_level)
            .map(item => item.count);
        }
      }
    }

    console.log(this.data);

    this.isLoading = false;
  }

  public capitalize(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  public getSum(value: number[]): number {
    return value.reduce((prev, curr) => prev + curr, 0);
  }

  public getPercentageDisplay(n: number, d: number): string {
    const ratio = calculateRatio(n, d) * 100;
    
    return ratio.toFixed(2);
  }

  public printReport() {
    window.print();
  }

  public getAdministrationYear():string {
    if (!this.testWindow.window_code) return "";

    console.log(this.testWindow.window_code);

    const { year, month } = parseWindowCode(this.testWindow.window_code);

    console.log({
      year,
      month,
    });

    return `${year}-${+year + 1} FSA`;
  }
}
